import { useCallback, useContext } from 'react';
import { useWallet } from 'use-wallet';
import { decToBn } from '../utils/number';
import { useMethods } from './useMethods';

import { TransactionContext } from '../context/TransactionContext';

export const useWithdraw = (name, method = 'withdraw') => {
  const { account } = useWallet();
  const cMethods = useMethods(name);
  const { add, remove } = useContext(TransactionContext);

  const withdraw = useCallback(
    async (amount, decimals) => {
      try {
        add();
        // If ever we still have decimals after applying the decimals
        // we need to get rid of them.
        const value = decToBn(amount, decimals).toFixed(0, 1);
        const gas = await cMethods[method](value.toString()).estimateGas({
          from: account,
        });
        const tx = await cMethods[method](value.toString()).send({
          from: account,
          gas,
        });

        return tx.status;
      } finally {
        remove();
      }
    },
    [account, method, cMethods, add, remove]
  );

  return withdraw;
};
