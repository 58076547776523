import React from 'react';
import styled from 'styled-components';
import { UseWalletProvider } from 'use-wallet';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, PrivateRoute } from './components';
import ThemeProvider from './theme';
import { ModalsProvider, DrizzleProvider, VaultsProvider } from './context';
import Vaults from './pages/Vaults';
import Pools from './pages/Pools';
import Dashboard from './pages/Dashboard';
import { TransactionProvider } from './context/TransactionContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.bg1};
  height: 100%;
  width: 100%;
  ${isMobile ? 'min-height: 100vh;' : ''};
  color: ${({ theme }) => theme.text1};
`;

const Grow = styled.div`
  display: flex;
  flex: 1 0 auto;
  ${isMobile ? '' : 'min-height: 100vh;'};
  background-color: #000000;
`;

const Shrink = styled.div`
  display: flex;
  flex: 0 1 auto;
`;

const ETH_URL = 'https://mainnet.infura.io/v3/cb4a5ae28e874192b688d73008d7875e';
const chainId = Number(process.env.REACT_APP_CHAIN_ID);

const Providers = ({ children }) => (
  <ThemeProvider>
    <UseWalletProvider
      chainId={chainId}
      connectors={{
        walletconnect: {
          chainId,
          rpcUrl: ETH_URL,
        },
        walletlink: {
          url: ETH_URL,
          appName: 'Rally Vaults',
          appLogoUrl: 'https://vaults.rally.io/static/media/RallyIconW.4138e4f3.svg',
        },
      }}
    >
      <DrizzleProvider>
        <TransactionProvider>
          <VaultsProvider>
            <ModalsProvider>{children}</ModalsProvider>
          </VaultsProvider>
        </TransactionProvider>
      </DrizzleProvider>
    </UseWalletProvider>
  </ThemeProvider>
);

function App() {
  return (
    <Providers>
      <Container>
        <Router>
          <Shrink>
            <Header />
          </Shrink>
          <Grow>
            <Switch>
              <PrivateRoute path="/dashboard" exact component={Dashboard} />
              <PrivateRoute path="/vaults" exact component={Vaults} />
              <PrivateRoute path="/liquidity" exact component={Pools} />
              <Redirect from="/" to="/dashboard" />
              <Redirect from="/mobile" to="/vaults" />
            </Switch>
          </Grow>
          <Shrink />
        </Router>
      </Container>
      <ToastContainer />
    </Providers>
  );
}

export default App;
