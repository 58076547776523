import BN from 'bignumber.js';
import { useCallback, useMemo, useContext } from 'react';
import { useWallet } from 'use-wallet';
import { VaultsContext } from '../context';

import { useWithdraw } from './useWithdraw';
import { useCall } from './useCall';
import { bnToDec } from '../utils/number';
import { getTokenVaultContractName } from '../utils/token';

const SYMBOL = {
  'yDAI+yUSDC+yUSDT+yTUSD': 'yCRV',
  'yyDAI+yUSDC+yUSDT+yTUSD': 'yUSD',
  'rdyDAI+yUSDC+yUSDT+yTUSD': 'rdyCRV',
  'yDAI+yUSDC+yUSDT+yBUSD': 'crvBUSD',
  'yyDAI+yUSDC+yUSDT+yBUSD': 'ycrvBUSD',
  'rdyDAI+yUSDC+yUSDT+yBUSD': 'rdcrvBUSD',
  crvRenWSBTC: 'crvBTC',
  ycrvRenWSBTC: 'ycrvBTC',
  rdcrvRenWSBTC: 'rdcrvBTC',
};

export const useVaultPrices = (name, priceKey) => {
  const vault = `yVault${name}`;
  const { prices } = useContext(VaultsContext);
  const { account } = useWallet();
  const [decimals] = useCall(vault, 'decimals', 0);
  const [vBalance] = useCall(vault, 'balance', 0);
  const [balance] = useCall(vault, 'balanceOf', 0, account);
  const [totalSupply] = useCall(vault, 'totalSupply', 0);
  const [pricePerFullShare] = useCall(
    getTokenVaultContractName(name),
    'getPricePerFullShare',
    0
  );

  const getDepositedValueInUSD = useCallback(() => {
    let value = BN(vBalance).multipliedBy(balance);

    if (decimals < 18) {
      value = value.multipliedBy(`1e+${18 - decimals}`);
    }

    value = value
      .dividedBy(BN(totalSupply))
      .multipliedBy(BN(pricePerFullShare))
      .dividedBy(BN('1e+18'))
      .multipliedBy(BN(prices[priceKey].usd));

    return value;
  }, [totalSupply, vBalance, pricePerFullShare, prices, priceKey, balance, decimals]);

  const value = useMemo(() => {
    if (!name) {
      return {
        value: 0,
        decimals,
      };
    }

    return {
      value: getDepositedValueInUSD(),
      decimals,
    };
  }, [name, getDepositedValueInUSD, decimals]);

  return value;
};

export const useVault = (name) => {
  const vault = `yVault${name}`;
  const { account } = useWallet();
  const [symbol] = useCall(vault, 'symbol', '');
  const [vBalance] = useCall(vault, 'balance', 0);
  const [totalDeposits] = useCall(vault, 'totalDeposits', 0);
  const [balance, fetchBalance] = useCall(vault, 'balanceOf', 0, account);
  const [decimals] = useCall(vault, 'decimals', 0);
  const [earned, fetchEarned] = useCall(vault, 'earned', 0, account);
  const withdraw = useWithdraw(vault);

  const claimRewards = useCallback(async () => {
    await withdraw(0);
    fetchBalance();
    fetchEarned();
  }, [withdraw, fetchBalance, fetchEarned]);

  const getFormatedBalance = useCallback(
    () => Number(bnToDec(balance, decimals)).toFixed(2),
    [balance, decimals]
  );

  const getFormatedVBalance = useCallback(
    () => Number(bnToDec(vBalance, decimals)).toFixed(2),
    [vBalance, decimals]
  );

  const getFormatedTotalDeposits = useCallback(
    () => Number(bnToDec(totalDeposits, decimals)).toFixed(2),
    [totalDeposits, decimals]
  );

  return {
    name: vault,
    symbol: SYMBOL[symbol] ? SYMBOL[symbol] : symbol,
    vBalance,
    getFormatedVBalance,
    balance,
    decimals,
    getFormatedBalance,
    totalDeposits,
    getFormatedTotalDeposits,
    fetchBalance,
    fetchEarned,
    earned,
    claimRewards,
  };
};
