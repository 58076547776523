import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Grid, Row, Col } from 'react-flexbox-grid';

const Title = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  padding-bottom: 0px;
  text-align: left;
`;

const Subtitle = styled.div`
  font-size: 16px;
  padding-top: 0px;
  text-align: left;
`;

const VaultImage = styled.img`
  height: 40px;
`;

const VaultTopSection = styled(Row)`
  align-items: center;
`;

const CenteredRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VaultHeader = ({
  logo,
  title,
  yTokenSymbol,
  tokenSymbol,
  yTokenBalance,
  tokenBalance,
}) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <VaultTopSection>
        <Col xs={1}>
          <VaultImage src={logo} />
        </Col>
        <Col xs={11}>
          <CenteredRow center={'xs'}>
            <Col xs={5}>
              <Title xs={12}>{title}</Title>
              <Subtitle>{t('vault-subtitle', { symbol: tokenSymbol })}</Subtitle>
            </Col>
            <Col xs={3}>
              <Subtitle>{t('vault-estimated-apy')}</Subtitle>
              <Row>
                <Title xs={12}>0.00%</Title>
              </Row>
              <Row>
                <Title xs={12}>&nbsp;</Title>
              </Row>
            </Col>
            <Col xs={4}>
              <Subtitle>{t('vault-available')}</Subtitle>
              <Row>
                <Title xs={12}>
                  {tokenBalance} {tokenSymbol}
                </Title>
              </Row>
              <Row>
                <Title xs={12}>
                  {yTokenBalance} {yTokenSymbol}
                </Title>
              </Row>
            </Col>
          </CenteredRow>
        </Col>
      </VaultTopSection>
    </Grid>
  );
};

export const MemoizedVaultHeader = React.memo(VaultHeader);
