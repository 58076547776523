import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useWallet } from 'use-wallet';
import BN from 'bignumber.js';

import { useCall, useCallWithMultipleParams } from './useCall';
import useAllowance from './useAllowance';
import useApprove from './useApprove';
import { useMethods } from './useMethods';
import { useAddress } from './useAddress';

import { TransactionContext } from '../context/TransactionContext';
import { VaultsContext } from '../context';
import { decToBn, bnToDec, getFormatedValue } from '../utils/number';

export const usePoolToken = (index) => {
  const pool = 'NoMintLiquidityRewardPools';
  const name = `Pool_${index}`;
  const { account } = useWallet();
  const [isApproved, setIsApproved] = useState(false);
  const { add, remove } = useContext(TransactionContext);

  const pAddress = useAddress(name);
  const noMintLiquidityMethods = useMethods('NoMintLiquidityRewardPools');
  const { getAllowance, allowance, setAllowance } = useAllowance(name, pool);
  const { onApprove } = useApprove(name, pool);

  const [symbol] = useCall(name, 'symbol', '');
  const [balance, fetchBalance] = useCall(name, 'balanceOf', 0, account);
  const [decimals] = useCall(name, 'decimals', 0);

  useEffect(() => {
    if (!allowance || allowance <= 0) {
      return;
    }

    setIsApproved(true);
  }, [allowance]);

  const callApprove = useCallback(async () => {
    const tx = await onApprove();
    setIsApproved(tx);
  }, [onApprove, setIsApproved]);

  const callDeposit = useCallback(
    async (amount) => {
      if (!noMintLiquidityMethods) {
        return;
      }

      try {
        add();
        const value = decToBn(amount, decimals).toFixed(0, 1);
        const gas = await noMintLiquidityMethods
          .deposit(index, value > balance ? balance : value)
          .estimateGas({
            from: account,
          });
        await noMintLiquidityMethods
          .deposit(index, value > balance ? balance : value)
          .send({
            from: account,
            gas,
          });
      } finally {
        remove();
      }
    },
    [noMintLiquidityMethods, account, balance, index, decimals, remove, add]
  );

  const callWithdraw = useCallback(
    async (amount) => {
      if (!noMintLiquidityMethods) {
        return;
      }

      try {
        add();
        const value = decToBn(amount, decimals).toFixed(0, 1);
        const gas = await noMintLiquidityMethods.withdraw(index, value).estimateGas({
          from: account,
        });

        await noMintLiquidityMethods.withdraw(index, value).send({
          from: account,
          gas,
        });
      } finally {
        remove();
      }
    },
    [noMintLiquidityMethods, account, index, decimals, remove, add]
  );

  const getFormatedBalance = useCallback(
    () => Number(bnToDec(balance, decimals)).toFixed(8),
    [balance, decimals]
  );

  const pToken = useMemo(
    () => ({
      name,
      address: pAddress,
      symbol,
      balance,
      decimals,
      getFormatedBalance,
      getAllowance,
      setAllowance,
      isApproved,
      fetchBalance,
      callApprove,
      callDeposit,
      callWithdraw,
    }),
    [
      name,
      pAddress,
      symbol,
      balance,
      decimals,
      getFormatedBalance,
      getAllowance,
      setAllowance,
      isApproved,
      fetchBalance,
      callApprove,
      callDeposit,
      callWithdraw,
    ]
  );

  return pToken;
};

export const usePoolPrices = (index, address, vault, isVault) => {
  const name = `Pool_${index}`;
  const { account } = useWallet();
  const { prices } = useContext(VaultsContext);

  const [decimals] = useCall(`yVault${vault.name}`, 'decimals', 18);
  const [balanceToken] = useCall(`${vault.name}Token`, 'balanceOf', 0, address);
  const [balanceRLY] = useCall('RallyToken', 'balanceOf', 0, address);
  const [totalSupply] = useCall(name, 'totalSupply', 0);
  const [totalDeposits] = useCall(`yVault${vault.name}`, 'totalDeposits', 0);

  const [staked, setStaked] = useState(0);

  const [userInfo] = useCallWithMultipleParams(
    'NoMintLiquidityRewardPools',
    'userInfo',
    {},
    index,
    account
  );

  const getPoolPercentage = useCallback(
    () => BN(staked).dividedBy(BN(totalSupply)),
    [staked, totalSupply]
  );

  const getRLYBalance = useCallback(
    () => BN(balanceRLY).multipliedBy(getPoolPercentage()),
    [balanceRLY, getPoolPercentage]
  );

  const getRLYInUSD = useCallback(
    () => getRLYBalance().multipliedBy(prices['rally-2'].usd),
    [prices, getRLYBalance]
  );

  const getTokenBalance = useCallback(() => {
    const value = BN(isVault ? totalDeposits : balanceToken).multipliedBy(
      getPoolPercentage()
    );

    if (decimals < 18) {
      return value.multipliedBy(`1e+${18 - decimals}`);
    }

    return value;
  }, [isVault, totalDeposits, balanceToken, getPoolPercentage, decimals]);

  const getTokenInUSD = useCallback(
    () => getTokenBalance().multipliedBy(prices[vault.priceKey].usd),
    [getTokenBalance, prices, vault]
  );

  const getTotalLockedValue = useCallback(() => {
    return getRLYInUSD().plus(getTokenInUSD());
  }, [getRLYInUSD, getTokenInUSD]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    setStaked(userInfo.amount);
  }, [userInfo, vault, getTokenBalance]);

  const value = useMemo(
    () => ({
      value: bnToDec(getTotalLockedValue()).toFixed(2),
      token: getFormatedValue(getTokenBalance()),
      rly: getFormatedValue(getRLYBalance()),
      percentage: bnToDec(getPoolPercentage().multipliedBy(BN('100e+18'))).toFixed(5),
    }),
    [getTotalLockedValue, getRLYBalance, getTokenBalance, getPoolPercentage]
  );

  return value;
};

export const usePool = (index) => {
  const poolContract = 'NoMintLiquidityRewardPools';
  const { account } = useWallet();
  const [staked, setStaked] = useState(0);

  const [earned, fetchEarned] = useCallWithMultipleParams(
    poolContract,
    'pendingRally',
    0,
    index,
    account,
    1000 * 15
  );
  const [userInfo, fetchUserInfo] = useCallWithMultipleParams(
    poolContract,
    'userInfo',
    {},
    index,
    account
  );

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    setStaked(userInfo.amount);
  }, [userInfo]);

  const pool = useMemo(
    () => ({
      name: `Pool_${index}`,
      earned,
      staked,
      fetchStaked: fetchUserInfo,
      fetchEarned,
    }),
    [earned, staked, fetchUserInfo, fetchEarned, index]
  );

  return pool;
};
