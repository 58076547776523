import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';

import { Accordion, Button } from '../index';
import { bnToDec, bnToString } from '../../utils/number';
import { PoolHeader } from './PoolHeader';
import { usePool, usePoolToken } from '../../hooks/usePool';
import { useLiquidityAPY } from '../../hooks/useLiquidityAPY';
import { useVaultAPY } from '../../hooks/useVaultAPY';
import { useVault } from '../../hooks/useVault';
import { VaultsContext } from '../../context';
import {
  StyledCard,
  StyledInput,
  AccordionContent,
  WalletBalance,
  BalanceRow,
  ContainerCol,
  Earnings,
  TokenLogo,
  Maximum,
  ApprovalButton,
  Disclaimer,
} from './styles';

import RLY from '../../assets/img/RLY-logo.png';

const usePoolVaultAPY = (v, prices, blocks) => {
  const vault = useVault(v.name);

  const { apy7Days } = useVaultAPY(
    v.name,
    blocks,
    vault.getFormatedTotalDeposits(),
    prices['rally-2'].usd,
    prices[v.priceKey] ? prices[v.priceKey].usd : 0
  );

  return apy7Days;
};

const Pool = ({ index, fadeTime = 250, info }) => {
  const { t } = useTranslation();
  const { stake = true, unstake = true } = info;
  const { prices, blocks } = useContext(VaultsContext);
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [sAmount, setSAmount] = useState(0); // Deposit
  const [usAmount, setUSAmount] = useState(0); // Withdrawal

  const pool = usePool(index);
  const lpToken = usePoolToken(index);
  const liquidityAPY = useLiquidityAPY(index, info.address, info.isVault);
  const vaultAPY = usePoolVaultAPY(info.vault || {}, prices, blocks);

  useEffect(() => {
    if (!pool || !lpToken || !pool.staked || !lpToken.symbol || !isLoading) {
      return;
    }

    setLoading(false);
  }, [pool, lpToken, isLoading]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, fadeTime);
  }, [fadeTime]);

  const getFormatedEarned = useCallback(
    () => bnToDec(pool.earned).toFixed(3),
    [pool.earned]
  );
  const getFormatedStaked = useCallback(
    () => bnToDec(pool.staked, lpToken.decimals).toFixed(8),
    [pool.staked, lpToken.decimals]
  );

  const onApprove = useCallback(async () => {
    await lpToken.callApprove();
  }, [lpToken]);

  const onStakeValueChange = useCallback(
    (e) => {
      setSAmount(e.target.value);
    },
    [setSAmount]
  );

  const onUnstakeValueChange = useCallback(
    (e) => {
      setUSAmount(e.target.value);
    },
    [setUSAmount]
  );

  const onMaxStakeClick = useCallback(async () => {
    if (lpToken.isApproved && Number(lpToken.balance) > 0) {
      setSAmount(bnToString(lpToken.balance, lpToken.decimals));
    }
  }, [lpToken.balance, lpToken.decimals, lpToken.isApproved]);

  const onMaxUnstakeClick = useCallback(async () => {
    if (Number(pool.staked) > 0) {
      setUSAmount(bnToString(pool.staked, lpToken.decimals));
    }
  }, [pool, lpToken]);

  const onUnstake = useCallback(async () => {
    await lpToken.callWithdraw(usAmount);
    lpToken.fetchBalance();
    pool.fetchStaked();
    setUSAmount(0);
  }, [lpToken, usAmount, pool]);

  const onStake = useCallback(async () => {
    await lpToken.callDeposit(sAmount);
    lpToken.fetchBalance();
    pool.fetchStaked();
    setSAmount(0);
  }, [lpToken, sAmount, pool]);

  const onClaim = useCallback(async () => {
    await lpToken.callWithdraw(0);
    pool.fetchEarned();
  }, [lpToken, pool]);

  return (
    <StyledCard $visible={visible ? visible.toString() : undefined}>
      <Accordion
        top={
          <PoolHeader
            logos={info.logos}
            title={info.title}
            subtitle={info.subtitle}
            link={info.link}
            earned={getFormatedEarned()}
            balance={lpToken.getFormatedBalance()}
            staked={getFormatedStaked()}
            liquidityAPY={stake ? liquidityAPY : 0}
            vaultAPY={stake ? vaultAPY : 0}
          />
        }
        isLoading={isLoading}
      >
        <AccordionContent>
          <Row center={'xs'}>
            <Earnings xs={6}>
              <div>{getFormatedEarned()} RLY</div>
              <TokenLogo src={RLY} />
            </Earnings>
            <Col xs={6}>
              <Button text={t('pool-claim')} onClick={onClaim} async={true} />
            </Col>
          </Row>
          <Row>
            <ContainerCol xs={6}>
              <BalanceRow>
                <Col xs={10}>
                  <Row>
                    <Col>{t('pool-balance')}:</Col>
                    <WalletBalance>{lpToken.getFormatedBalance()}</WalletBalance>
                  </Row>
                </Col>
                <Maximum
                  onClick={onMaxStakeClick}
                  xs={2}
                  $active={lpToken.isApproved && Number(lpToken.balance) > 0}
                >
                  Max
                </Maximum>
              </BalanceRow>
              <Row>
                <StyledInput
                  value={sAmount}
                  disabled={!lpToken.isApproved || Number(lpToken.balance) === 0}
                  onChange={onStakeValueChange}
                  onBlur={onStakeValueChange}
                  type="number"
                  required
                  min={0}
                  max={lpToken.getFormatedBalance()}
                />
              </Row>
            </ContainerCol>
            <ContainerCol xs={6}>
              <BalanceRow>
                <Col xs={10}>
                  <Row>
                    <Col>{t('pool-staked')}:</Col>
                    <WalletBalance>{getFormatedStaked()}</WalletBalance>
                  </Row>
                </Col>
                <Maximum
                  onClick={onMaxUnstakeClick}
                  xs={2}
                  $active={Number(pool.staked) > 0}
                >
                  Max
                </Maximum>
              </BalanceRow>
              <Row>
                <StyledInput
                  value={usAmount}
                  disabled={Number(pool.staked) === 0}
                  onChange={onUnstakeValueChange}
                  onBlur={onUnstakeValueChange}
                  type="number"
                  required
                  min={0}
                  max={getFormatedStaked()}
                />
              </Row>
            </ContainerCol>
          </Row>
          <Row center={'xs'}>
            <ContainerCol xs={6}>
              <Row>
                {!lpToken.isApproved && (
                  <ApprovalButton
                    text={t('action-unlock')}
                    onClick={onApprove}
                    outline={false}
                    async={true}
                  />
                )}
                {lpToken.isApproved && (
                  <Button
                    text={t('pool-stake')}
                    onClick={onStake}
                    disabled={Number(lpToken.balance) === 0 || !stake}
                    async={true}
                  />
                )}
              </Row>
            </ContainerCol>
            <ContainerCol xs={6}>
              <Row>
                <Button
                  text={t('pool-unstake')}
                  onClick={onUnstake}
                  disabled={Number(pool.staked) === 0 || !unstake}
                  async={true}
                />
              </Row>
            </ContainerCol>
          </Row>
          <Row center={'xs'}>
            <Disclaimer xs={12}>
              RLY earned is automatically claimed on Stake & Unstake.
            </Disclaimer>
          </Row>
        </AccordionContent>
      </Accordion>
    </StyledCard>
  );
};

export default Pool;
