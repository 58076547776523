import { useState, useCallback, useEffect } from 'react';
import BN from 'bignumber.js';

import useTokenPrices from './useTokenPrices';
import { useCall } from './useCall';
import { useAddress } from './useAddress';

export const useLiquidityAPY = (index, address, isVaultAPY) => {
  const name = `Pool_${index}`;
  const poolContract = 'NoMintLiquidityRewardPools';
  const blocksPerYear = BN((60 * 60 * 24 * 365) / 13);

  const prices = useTokenPrices();
  const [allocPoint, setAllocPoint] = useState(0);
  const [APY, setAPY] = useState('-');

  const pAddress = useAddress(poolContract);

  const [balanceRALLY] = useCall('RallyToken', 'balanceOf', 0, address);
  const [balanceUSDC] = useCall('USDCToken', 'balanceOf', 0, address);
  const [balanceWETH] = useCall('WETHToken', 'balanceOf', 0, address);
  const [poolInfo] = useCall(poolContract, 'poolInfo', {}, index);
  const [rallyPerBlock] = useCall(poolContract, 'rallyPerBlock', 0);
  const [totalAllocPoint] = useCall(poolContract, 'totalAllocPoint', 0);
  const [poolBalance] = useCall(name, 'balanceOf', 0, pAddress);
  const [totalSupply] = useCall(name, 'totalSupply', 0);

  useEffect(() => {
    if (!poolInfo) {
      return;
    }
    setAllocPoint(poolInfo.allocPoint);
  }, [poolInfo]);

  const getAPY = useCallback(() => {
    if (typeof index !== 'number' || !address) {
      return 0;
    }

    let totalLockedValue = 0;
    if (isVaultAPY) {
      totalLockedValue = BN(poolBalance);
    } else {
      const RLY = BN(balanceRALLY).multipliedBy(prices['rally-2'].usd);
      const USDC = BN(balanceUSDC)
        .multipliedBy(prices['usd-coin'].usd)
        .multipliedBy(BN('1e+12'));
      const WETH = BN(balanceWETH).multipliedBy(prices['weth'].usd);

      totalLockedValue = RLY.plus(USDC)
        .plus(WETH)
        .multipliedBy(BN(poolBalance))
        .dividedBy(BN(totalSupply));
    }

    const rallyPerBlockInPool = BN(rallyPerBlock)
      .multipliedBy(BN(allocPoint))
      .dividedBy(BN(totalAllocPoint));

    const calculated = rallyPerBlockInPool
      .multipliedBy(blocksPerYear)
      .multipliedBy(prices['rally-2'].usd)
      .dividedBy(totalLockedValue);

    return calculated.multipliedBy(100).toNumber().toFixed(2);
  }, [
    index,
    address,
    isVaultAPY,
    allocPoint,
    balanceRALLY,
    balanceUSDC,
    balanceWETH,
    blocksPerYear,
    poolBalance,
    prices,
    rallyPerBlock,
    totalAllocPoint,
    totalSupply,
  ]);

  useEffect(() => {
    const run = () => {
      setAPY(getAPY());
    };

    run();

    const timer = setInterval(run, 1000 * 10);
    return () => clearInterval(timer);
  }, [getAPY, setAPY]);

  return APY;
};
