import { useContext, useEffect, useState } from 'react';
import { DrizzleContext } from '../context/DrizzleContext';

export const useAddress = (name) => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    if (!drizzle || !initialized || !drizzle.contracts[name]) {
      return;
    }

    setAddress(drizzle.contracts[name].address);
  }, [drizzle, initialized, name]);

  return address;
};

export const useAbi = (name) => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [abi, setAbi] = useState(null);

  useEffect(() => {
    if (!drizzle || !initialized || !drizzle.contracts[name]) {
      return;
    }

    setAbi(drizzle.contracts[name].abi);
  }, [drizzle, initialized, name]);

  return abi;
};
