import { useCallback, useContext } from 'react';
import { useWallet } from 'use-wallet';
import { useMethods } from './useMethods';
import { useAddress } from './useAddress';
import { TransactionContext } from '../context/TransactionContext';
import { BN } from 'bn.js';

const MAX_APPROVAL = new BN(2).pow(new BN(256)).sub(new BN(1));

const approve = async (methods, to, from) => {
  const gas = await methods.approve(to, MAX_APPROVAL).estimateGas({
    from,
  });
  return methods.approve(to, MAX_APPROVAL).send({
    from,
    gas,
  });
};

const useApprove = (token, vault) => {
  const { account } = useWallet();
  const tMethods = useMethods(token);
  const vAddress = useAddress(vault);
  const { add, remove } = useContext(TransactionContext);

  const handleApprove = useCallback(async () => {
    if (!vAddress || !tMethods) {
      return false;
    }

    try {
      add();
      const tx = await approve(tMethods, vAddress, account);
      return tx.status;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      remove();
    }
  }, [tMethods, account, vAddress, add, remove]);

  return { onApprove: handleApprove };
};

export default useApprove;
