import { useContext, useEffect, useState } from 'react';
import { DrizzleContext } from '../context/DrizzleContext';
import { getTokenVaultContractName } from '../utils/token';

export const useVaults = (names) => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [vaults, setVaults] = useState('');

  useEffect(() => {
    if (!drizzle || !initialized) {
      return;
    }

    const results = names.reduce((acc, { name }) => {
      acc[name] = {
        methods: drizzle.contracts[`yVault${name}`].methods,
        address: drizzle.contracts[`yVault${name}`].address,
      };

      const token = getTokenVaultContractName(name);
      acc[token] = {
        methods: drizzle.contracts[token].methods,
        address: drizzle.contracts[token].address,
      };
      return acc;
    }, {});

    setVaults(results);
  }, [drizzle, initialized, names]);

  return vaults;
};
