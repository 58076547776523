import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 12px;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
`;

export const Card = ({ children, className }) => (
  <StyledCard className={className}>{children}</StyledCard>
);
