import { useCallback, useState, useEffect } from 'react';
import { useWallet } from 'use-wallet';
import { useMethods } from './useMethods';
import { useAddress } from './useAddress';

const getAllowance = async (methods, owner, spender) => {
  return methods.allowance(owner, spender).call();
};

const useAllowance = (token, vault) => {
  const { account } = useWallet();
  const [allowance, setAllowance] = useState(0);
  const tMethods = useMethods(token);
  const vAddress = useAddress(vault);

  const fetchAllowance = useCallback(async () => {
    if (!vAddress || !tMethods) {
      return -1;
    }

    try {
      const tx = await getAllowance(tMethods, account, vAddress);
      setAllowance(tx);
    } catch (e) {
      console.log(`Error getting allowance for vault: ${vAddress}, spender: ${account}`);
      return 0;
    }
  }, [account, tMethods, vAddress]);

  useEffect(() => {
    if (!vAddress || !tMethods) {
      return;
    }

    fetchAllowance();

    let refreshInterval = setInterval(fetchAllowance, 10000);
    return () => clearInterval(refreshInterval);
  }, [fetchAllowance, vAddress, tMethods]);

  return { getAllowance: fetchAllowance, allowance, setAllowance };
};

export default useAllowance;
