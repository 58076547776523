import React, { useState, useCallback, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { Card, Accordion } from '../index';
import { fadeIn, fadeOut } from '../../theme/animations';
import { TOKEN_TYPES } from '../../utils/token';
import { useVaultAPY } from '../../hooks/useVaultAPY';
import { useLiquidityAPY } from '../../hooks/useLiquidityAPY';
import { useVault } from '../../hooks/useVault';
import { useToken } from '../../hooks/useToken';
import { MemoizedVaultHeader } from './VaultHeader';
import { MemoizedVaultContent } from './VaultContent';
import { VaultsContext } from '../../context';

const StyledCard = styled(Card)`
  border: solid 1px rgba(255, 255, 255, 0.5);
  background-color: #000000;
  margin-bottom: 1em;
  border-radius: 17px;
  z-index: 1;

  visibility: ${({ $visible }) => (!$visible ? 'hidden' : 'visible')};
  animation: ${({ $visible }) => (!$visible ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
`;

const Vault = (props) => {
  const {
    name,
    logo,
    deposits = true,
    withdraws = true,
    fadeTime = 250,
    priceKey,
    liquidity = {},
  } = props;
  const { prices, blocks } = useContext(VaultsContext);
  const [options, setOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [activeTokenName, setActiveTokenName] = useState(name);
  const liquidityAPY = useLiquidityAPY(liquidity.index, liquidity.address, true);

  const onActiveTokenChange = useCallback(
    (o) => {
      setActiveTokenName(o.value);
    },
    [setActiveTokenName]
  );
  const vault = useVault(name);
  const yToken = useToken(name, TOKEN_TYPES.Y_TOKEN);
  const token = useToken(name);

  console.log('price key', ` ${priceKey} ${prices[priceKey]}`);

  const { apy7Days } = useVaultAPY(
    name,
    blocks,
    vault.getFormatedVBalance(),
    prices['rally-2'].usd,
    prices[priceKey].usd
  );

  useEffect(() => {
    if (!token || !yToken || !token.symbol || !yToken.symbol) {
      return;
    }

    if (options.length > 1) {
      isLoading && setLoading(false);
      return;
    }

    setOptions([
      { value: token.name, label: token.symbol },
      { value: yToken.name, label: yToken.symbol },
    ]);
  }, [token, yToken, isLoading, options.length]);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, fadeTime);
  }, [fadeTime]);

  const onClaimRewards = useCallback(async () => {
    await vault.claimRewards();
  }, [vault]);

  return (
    <StyledCard $visible={visible}>
      <Accordion
        top={
          <MemoizedVaultHeader
            logo={logo}
            title={yToken.title}
            yTokenSymbol={yToken.symbol}
            tokenSymbol={token.symbol}
            yTokenBalance={yToken.getFormatedBalance()}
            tokenBalance={token.getFormatedBalance()}
            apy7Days={apy7Days}
            liquidityAPY={liquidityAPY}
          />
        }
        isLoading={isLoading}
        opened={vault.balance > 0 || vault.earned > 0}
      >
        <MemoizedVaultContent
          vaultBalance={vault.balance}
          vaultDecimals={vault.decimals}
          vaultEarned={vault.earned}
          vaultFormatedBalance={vault.getFormatedBalance()}
          vaultSymbol={vault.symbol}
          fetchBalance={vault.fetchBalance}
          claimRewards={onClaimRewards}
          deposits={deposits}
          withdraws={withdraws}
          options={options}
          activeToken={activeTokenName === token.name ? token : yToken}
          onActiveTokenChange={onActiveTokenChange}
        />
      </Accordion>
    </StyledCard>
  );
};

export default Vault;
