import { useState, useCallback, useEffect } from 'react';
import { useWallet } from 'use-wallet';

import { useDeposit } from './useDeposit';
import { useWithdraw } from './useWithdraw';
import useApprove from './useApprove';
import { useCall } from './useCall';
import useAllowance from './useAllowance';

import { bnToDec } from '../utils/number';
import { TOKEN_TYPES, getTokenContractName } from '../utils/token';

const TITLE = {
  'yDAI+yUSDC+yUSDT+yTUSD': 'curve.fi/y LP',
  'yyDAI+yUSDC+yUSDT+yTUSD': 'curve.fi/y LP',
  'yDAI+yUSDC+yUSDT+yBUSD': 'curve.fi/busd LP',
  'yyDAI+yUSDC+yUSDT+yBUSD': 'curve.fi/busd LP',
  crvRenWSBTC: 'curve.fi/sbtc LP',
  ycrvRenWSBTC: 'curve.fi/sbtc LP',
};

const SYMBOL = {
  'yDAI+yUSDC+yUSDT+yTUSD': 'yCRV',
  'yyDAI+yUSDC+yUSDT+yTUSD': 'yUSD',
  'rdyDAI+yUSDC+yUSDT+yTUSD': 'rdyCRV',
  'yDAI+yUSDC+yUSDT+yBUSD': 'crvBUSD',
  'yyDAI+yUSDC+yUSDT+yBUSD': 'ycrvBUSD',
  'rdyDAI+yUSDC+yUSDT+yBUSD': 'rdcrvBUSD',
  crvRenWSBTC: 'crvBTC',
  ycrvRenWSBTC: 'ycrvBTC',
  rdcrvRenWSBTC: 'rdcrvBTC',
};

export const useToken = (name, type = TOKEN_TYPES.UNDERLYING) => {
  const vault = `yVault${name}`;
  const token = getTokenContractName(type, name);

  const [isApproved, setIsApproved] = useState(false);

  const { account } = useWallet();

  const [symbol] = useCall(token, 'symbol', '');
  const [balance, fetchBalance] = useCall(token, 'balanceOf', 0, account);
  const [decimals] = useCall(token, 'decimals', 0);
  const { onApprove } = useApprove(token, vault);
  const { allowance } = useAllowance(token, vault);
  const deposit = useDeposit(
    vault,
    type === TOKEN_TYPES.UNDERLYING ? 'deposit' : 'deposityToken'
  );
  const withdraw = useWithdraw(
    vault,
    type === TOKEN_TYPES.UNDERLYING ? 'withdraw' : 'withdrawyToken'
  );

  useEffect(() => {
    if (!allowance || allowance <= 0) {
      return;
    }

    setIsApproved(true);
  }, [allowance]);

  const getFormatedBalance = useCallback(
    () => Number(bnToDec(balance, decimals)).toFixed(2),
    [balance, decimals]
  );

  const callApprove = useCallback(async () => {
    const tx = await onApprove();
    setIsApproved(tx);
  }, [onApprove, setIsApproved]);

  const callDeposit = useCallback(
    async (amount) => {
      await deposit(Number(amount) > Number(balance) ? balance : amount, decimals);
      fetchBalance();
    },
    [deposit, balance, decimals, fetchBalance]
  );

  const callWithdraw = useCallback(
    async (amount) => {
      await withdraw(amount, decimals);
      fetchBalance();
    },
    [withdraw, decimals, fetchBalance]
  );

  return {
    name: token,
    title: TITLE[symbol] ? TITLE[symbol] : symbol,
    symbol: SYMBOL[symbol] ? SYMBOL[symbol] : symbol,
    decimals,
    balance,
    allowance,
    isApproved,
    getFormatedBalance,
    callApprove,
    callDeposit,
    callWithdraw,
  };
};
