import React, { useCallback } from 'react';
import { Button } from '../../Button';
import { useWallet } from 'use-wallet';
import { useTranslation } from 'react-i18next';
import { Modal, ModalActions, ModalContent } from '../../Modal';

const AccountModal = ({ onDismiss }) => {
  const { t } = useTranslation();
  const { _web3ReactContext, reset } = useWallet();

  const handleSignOutClick = useCallback(() => {
    onDismiss && onDismiss();
    reset();
    if (
      _web3ReactContext &&
      _web3ReactContext.connector &&
      _web3ReactContext.connector.close
    ) {
      // Actually close the connection
      _web3ReactContext.connector.close();
    }
  }, [reset, _web3ReactContext, onDismiss]);

  return (
    <Modal>
      <ModalContent>
        <Button
          onClick={handleSignOutClick}
          text={t('account-sign-out')}
          variant="secondary"
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onDismiss} text={t('account-cancel')} outline={false} />
      </ModalActions>
    </Modal>
  );
};

export default AccountModal;
