import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { useTranslation } from 'react-i18next';

import EXT_LINK from '../../assets/img/ext-link.svg';

const ExternalLink = styled.a`
  width: 18px;
  height: 18px;

  img {
    width: 18px;
    height: 18px;
  }
`;

const Title = styled(Row)`
  font-size: 24px;
`;

const Subtitle = styled(Row)`
  font-size: 16px;
  font-family: SofiaPro-Light;
`;

const TopContent = styled(Row)`
  display: flex;
  align-items: flex-end;
`;

const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const BottomContent = styled(TopContent)`
  padding-top: 1em;
`;

const TokenLogo = styled.img`
  height: 25px;
  width: 25px;
  padding-left: 5px;
`;

const PoolStatisticValue = styled(Row)`
  font-size: 1.5em;
  font-family: 'Roboto Mono';
`;

const PoolStatistic = React.memo(({ size = 4, value, subtitle }) => {
  return (
    <Col xs={size}>
      <PoolStatisticValue>{value}</PoolStatisticValue>
      <Subtitle>{subtitle}</Subtitle>
    </Col>
  );
});

const Logos = ({ logos }) => {
  const logosList = logos.map((src) => {
    if (src) {
      return <TokenLogo key={src} src={src} alt={'token logo'} />;
    }

    return null;
  });

  return <Row>{logosList}</Row>;
};

export const PoolHeader = React.memo(
  ({ logos, title, subtitle, link, balance, earned, staked, liquidityAPY, vaultAPY }) => {
    const { t } = useTranslation();
    return (
      <Grid>
        <Row>
          <CenteredCol xs={4}>
            <Logos logos={logos} />
            <Title>
              {title}
              <ExternalLink href={link} target={'_blank'} rel={'noopener noreferrer'}>
                <img src={EXT_LINK} alt={'external link to pool'} />
              </ExternalLink>
            </Title>
            <Subtitle>{subtitle ? subtitle : <>&nbsp;</>}</Subtitle>
          </CenteredCol>
          <Col xs={8}>
            <TopContent>
              <PoolStatistic value={earned} subtitle={t('pool-earned')} />
              <PoolStatistic value={balance} subtitle={t('pool-balance')} />
              <PoolStatistic value={staked} subtitle={t('pool-staked')} />
            </TopContent>
            <BottomContent>
              <PoolStatistic
                value={`${
                  isNaN(vaultAPY) || isNaN(liquidityAPY)
                    ? '--'
                    : (Number(vaultAPY) + Number(liquidityAPY)).toFixed(2)
                }%`}
                subtitle={t('pool-apy')}
              />
            </BottomContent>
          </Col>
        </Row>
      </Grid>
    );
  }
);
