import BigNumber from 'bignumber.js';

export const bnToDec = (value, decimals = 18) => {
  return Number(bnToString(value, decimals));
};

export const bnToString = (value, decimals = 18) => {
  let strValue = `${value}`;
  let hasDecimals = strValue.indexOf('.');

  const bn = new BigNumber(value);
  if (bn.toNumber() > 0 && bn.e < decimals) {
    const diff = decimals - bn.e;
    if (hasDecimals) {
      const before = strValue.substring(0, hasDecimals);
      const after = strValue.substring(hasDecimals + 1, strValue.length);
      strValue = `${before}${after}`;
    }

    return `0.${Array.from({ length: diff - 1 })
      .map((i) => 0)
      .join('')}${strValue}`;
  }

  // We want the full value here no decimals
  const hasExponent = strValue.indexOf('e');
  if (hasExponent > 0) {
    strValue = strValue.substring(0, hasExponent);
    const before = strValue.substring(0, hasDecimals);
    const after = strValue.substring(hasDecimals + 1, strValue.length);
    if (after.length < bn.e) {
      strValue = `${before}${after}${Array.from({ length: bn.e - after.length })
        .map((i) => 0)
        .join('')}`;
    } else if (after.length > bn.e) {
      strValue = `${before}${after}`;

      const exponent = bn.e + 1;

      strValue = `${strValue.substring(0, exponent)}.${strValue.substring(
        exponent,
        strValue.length
      )}`;

      return bnToString(strValue, decimals);
    } else if (after.length === bn.e) {
      strValue = strValue.replace('.', '');
    }
  } else {
    // Do we still have decimals?
    hasDecimals = strValue.indexOf('.');
    if (hasDecimals > 0) {
      strValue = strValue.substring(0, hasDecimals);
    }
  }

  return `${strValue.substring(0, strValue.length - decimals)}.${strValue.substring(
    strValue.length - decimals,
    strValue.length
  )}`;
};

export const decToBn = (dec, decimals = 18) => {
  return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals));
};

export const bnToFormatedString = (value, decimals = 18, digits = 2) => {
  return bnToDec(value, decimals).toLocaleString('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const getFormatedValue = (value, decimals = 18) => {
  const dec = bnToDec(value, decimals);
  let nbDigits = 3;
  if (dec < 1) {
    nbDigits = 6;
  } else if (dec < 100) {
    nbDigits = 5;
  } else if (dec < 1000) {
    nbDigits = 4;
  } else if (dec < 10000) {
    nbDigits = 2;
  } else if (dec >= 10000) {
    nbDigits = 0;
  }

  return bnToFormatedString(value, decimals, nbDigits);
};
