import ETH from './assets/img/ETH-logo.png';
import RLY from './assets/img/RLY-logo.png';
import USDC from './assets/img/USDC-logo.png';
import CRV3 from './assets/img/3Pools-logo.png';
import DAI from './assets/img/DAI-logo.png';
// import USDT from './assets/img/USDT-logo.png';
import YCRV from './assets/img/yCRV-logo.png';
import YFI from './assets/img/YFI-logo.png';
//import crvBTC from './assets/img/crvBTC-logo.png';
import WETH from './assets/img/WETH-logo.png';
import TUSD from './assets/img/TUSD-logo.png';

export const VAULTS = {
  V1: [
    { name: 'USDC', logo: USDC, deposits: false, priceKey: 'usd-coin' },
    { name: 'TUSD', logo: TUSD, deposits: false, priceKey: 'true-usd' },
    { name: 'DAI', logo: DAI, deposits: false, priceKey: 'dai' },
    // {name: 'USDT', logo: USDT false, priceKey: 'usd-coin'},
    { name: 'YFI', logo: YFI, deposits: false, priceKey: 'yearn-finance' },
    { name: 'WETH', logo: WETH, deposits: false, priceKey: 'ethereum' },
    {
      name: 'YCRV',
      logo: YCRV,
      deposits: false,
      priceKey: 'lp-3pool-curve',
    },
    { name: 'crvBUSD', logo: YCRV, deposits: false, priceKey: 'lp-bcurve' },
    //{ name: 'crvBTC', logo: crvBTC, deposits: false, priceKey: 'lp-sbtc-curve' },
  ],
  V2: [
    { name: 'USDC_V2', logo: USDC, deposits: false, priceKey: 'usd-coin' },
    { name: 'DAI_V2', logo: DAI, deposits: false, priceKey: 'dai' },
    { name: 'YFI_V2', logo: YFI, deposits: false, priceKey: 'yearn-finance' },
    { name: 'YCRV_V2', logo: YCRV, deposits: false, priceKey: 'lp-3pool-curve' },
    { name: 'crvBUSD_V2', logo: YCRV, deposits: false, priceKey: 'lp-bcurve' },
    {
      name: 'CRV3',
      logo: CRV3,
      deposits: false,
      priceKey: 'lp-3pool-curve',
      liquidity: {
        index: 5,
        address: '0x398f6c9e0a6490da189885283880be8df92bad36',
      },
    },
  ],
};

export const POOLS = [
  {
    title: 'UNI RLY/ETH',
    subtitle: '',
    link: 'https://v2.info.uniswap.org/pair/0x27fd0857f0ef224097001e87e61026e39e1b04d1/',
    logos: [RLY, ETH],
    address: '0x27fd0857f0ef224097001e87e61026e39e1b04d1',
    vault: {
      name: 'WETH',
      priceKey: 'ethereum',
    },
  },
  {
    title: 'BAL RLY/USDC',
    subtitle: '90-10',
    link: 'https://pools.balancer.exchange/#/pool/0x0badc63d5cc7a25007f7c35acc23369c00df6ee7/',
    logos: [RLY, USDC],
    address: '0x0badc63d5cc7a25007f7c35acc23369c00df6ee7',
    vault: {
      name: 'USDC',
      priceKey: 'usd-coin',
    },
  },
  {
    title: 'BAL RLY/USDC',
    subtitle: '10-90',
    link: 'https://pools.balancer.exchange/#/pool/0xf7dde178fabe8386ade62d584019326a203b5394/',
    logos: [RLY, USDC],
    address: '0xf7dde178fabe8386ade62d584019326a203b5394',
    vault: {
      name: 'USDC',
      priceKey: 'usd-coin',
    },
  },
  {
    title: 'BAL RLY/ETH',
    subtitle: '90-10',
    link: 'https://pools.balancer.exchange/#/pool/0xf1880d81ce7d3b4b0d96ea0665cdb03cb7332c52/',
    logos: [RLY, ETH],
    address: '0xf1880d81ce7d3b4b0d96ea0665cdb03cb7332c52',
    vault: {
      name: 'WETH',
      priceKey: 'ethereum',
    },
  },
  {
    title: 'BAL RLY/ETH',
    subtitle: '10-90',
    link: 'https://pools.balancer.exchange/#/pool/0x96d99093f22719dd06fb8db8e93779979a2acab3/',
    logos: [RLY, ETH],
    address: '0x96d99093f22719dd06fb8db8e93779979a2acab3',
    vault: {
      name: 'WETH',
      priceKey: 'ethereum',
    },
  },
  {
    title: 'curve.fi/3pool LP',
    subtitle: '',
    link: 'https://vaults.rally.io',
    logos: [null, CRV3],
    address: '0x398f6c9e0a6490da189885283880be8df92bad36',
    isVault: true,
    vault: {
      name: 'CRV3',
      priceKey: 'lp-3pool-curve',
    },
    stake: false,
  },
];
