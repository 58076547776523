import { useCallback, useEffect, useState } from 'react';
import fetch from 'node-fetch';

export const DEFAULT_PRICES = {
  'rally-2': { usd: 0 },
  'usd-coin': { usd: 0 },
  weth: { usd: 0 },
  dai: { usd: 0 },
  'yearn-finance': { usd: 0 },
  'lp-3pool-curve': { usd: 0 },
  'lp-bcurve': { usd: 0 },
  'true-usd': { usd: 0 },
  ethereum: { usd: 0 },
  //'lp-sbtc-curve': { usd: 0 },
};

export const useTokenPrices = () => {
  const [prices, setPrices] = useState(DEFAULT_PRICES);

  const getTokenPrices = useCallback(async () => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=${Object.keys(
          DEFAULT_PRICES
        ).join(',')}`,
        {
          headers: {
            Accept: 'application/json',
          },
        }
      )
        .then((res) => res.text())
        .then((res) => {
          resolve(JSON.parse(res));
        })
        .catch((err) => {
          reject();
        });
    });
  }, []);

  useEffect(() => {
    const run = async () => {
      const current = await getTokenPrices();
      setPrices(current);
    };

    run();

    const timer = setInterval(run, 1000 * 60 * 5);
    return () => clearInterval(timer);
  }, [getTokenPrices, setPrices]);

  return prices;
};

export default useTokenPrices;
