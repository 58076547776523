import React from 'react';
import styled from 'styled-components';
import { Container } from '../components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Pool from '../components/Pool';
import BetaWarning from '../components/BetaWarning';
import Forms from '../assets/img/forms.svg';

import { POOLS } from '../data';

const StyledContainer = styled(Container)`
  padding-top: 2em;
  position: relative;
  z-index: 0;
`;

const ImageContainer = styled(Row)`
  position: relative;
  opacity: 0.5;
`;

const Image = styled.img`
  z-index: 0;
  position: absolute;
`;

const Spacer = styled(Row)`
  padding: ${(props) => (props.space || 1) * 1}em 0;
`;

const PoolCards = ({ pools }) => {
  const poolsList = pools.map((v, i) => (
    <Row center={'xs'} key={`${v.title}-${v.subtitle}`}>
      <Pool index={i} fadeTime={i * 100} info={v} />
    </Row>
  ));

  return poolsList;
};

const Pools = () => {
  return (
    <Container size={'lg'}>
      <ImageContainer center={'xs'}>
        <Image src={Forms} width={'100%'} alt={'Geometric Forms'} />
      </ImageContainer>
      <StyledContainer size={'md'}>
        <Grid fluid>
          <Col xs={12}>
            <Row center={'xs'}>
              <BetaWarning xs={6} />
            </Row>
            <Spacer />
            <PoolCards pools={POOLS} />
          </Col>
        </Grid>
      </StyledContainer>
    </Container>
  );
};

export default Pools;
