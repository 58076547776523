import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-flexbox-grid';

const Container = styled(Col)`
  font-size: 18px;
  padding: 10px;
  line-height: 1.14;
  text-align: center;
  color: #ffffff;
  border: 1px solid white;
  border-radius: 10px;
  z-index: 1;
  background: #000000;
`;

export const BetaWarning = (props) => {
  const { t } = useTranslation();
  return (
    <Container {...props}>
      {t('beta-warning-1')}
      <br />
      {t('beta-warning-2')}
    </Container>
  );
};

export default BetaWarning;
