import { useContext, useEffect, useState } from 'react';
import { DrizzleContext } from '../context/DrizzleContext';
import { POOLS } from '../data';

export const useMethods = (name) => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [methods, setMethods] = useState('');

  useEffect(() => {
    if (!drizzle || !initialized || !drizzle.contracts[name]) {
      return;
    }

    setMethods(drizzle.contracts[name].methods);
  }, [drizzle, initialized, name]);

  return methods;
};

export const usePoolMethods = () => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [methods, setMethods] = useState('');

  useEffect(() => {
    if (!drizzle || !initialized || !drizzle.contracts['Pool_0']) {
      return;
    }

    const pMethods = POOLS.map((_, i) => drizzle.contracts[`Pool_${i}`].methods).flat();

    setMethods(pMethods);
  }, [drizzle, initialized]);

  return methods;
};
