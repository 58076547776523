import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-flexbox-grid';

const Container = styled(Col)`
  font-size: 18px;
  padding: 10px;
  line-height: 1.14;
  text-align: center;
  color: #ffffff;
  border: 1px solid white;
  border-radius: 10px;
  z-index: 1;
  background: #000000;
`;

export const StyledExternalLink = styled.a`
  color: #fff;
  &:hover {
    color: ${({ theme }) => theme.text2};
  }
`;

export const CloseWarning = (props) => {
  return (
    <Container {...props}>
      Vaults are no longer generating $RLY rewards. Please withdraw at your earliest
      convenience.
      <br />
      <br />
      See the{' '}
      <StyledExternalLink
        href={
          'https://snapshot.page/#/rally/proposal/QmS2R1Gsyo9dqHcqZFq81GzTMiG2fnkYzJ1qGXQLQKSXX3'
        }
        target="__blank"
        rel={'noopener noreferrer'}
      >
        recent proposal
      </StyledExternalLink>{' '}
      and{' '}
      <StyledExternalLink
        href={
          'https://forum.rally.io/t/retiring-yield-delegating-vaults-and-next-steps/411'
        }
        target="__blank"
        rel={'noopener noreferrer'}
      >
        forum discussion
      </StyledExternalLink>{' '}
      for more details
    </Container>
  );
};

export default CloseWarning;
