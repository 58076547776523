import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Button } from '../components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { fadeIn, fadeOut } from '../theme/animations';
import WalletProviderModal from './WalletProviderModal';
import useModal from '../hooks/useModal';
import BetaWarning from './BetaWarning';

import Forms from '../assets/img/forms.svg';

const Content = styled(Container)`
  position: relative;
  z-index: 0;
`;

const Title = styled(Col)`
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

const Spacer = styled(Row)`
  padding: ${(props) => (props.space || 1) * 1}em 0;
`;

const ImageContainer = styled(Row)`
  visibility: ${({ $visible }) => (!$visible ? 'hidden' : 'visible')};
  animation: ${({ $visible }) => (!$visible ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
`;

const TextContainer = styled.div`
  visibility: ${({ $visible }) => (!$visible ? 'hidden' : 'visible')};
  animation: ${({ $visible }) => (!$visible ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
`;

export const ConnectYourWallet = () => {
  const { t } = useTranslation();

  const [vText, setVisibleText] = useState(false);
  const [vImage, setVisibleImage] = useState(false);

  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />, 'provider');

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal();
  }, [onPresentWalletProviderModal]);

  useEffect(() => {
    setTimeout(() => {
      setVisibleImage(true);
    }, 100);

    setTimeout(() => {
      setVisibleText(true);
    }, 250);
  }, []);

  return (
    <Content size="lg">
      <Grid>
        <ImageContainer center={'xs'} $visible={vImage ? vImage.toString() : undefined}>
          <img src={Forms} width={'100%'} alt={'Geometric Forms'} />
        </ImageContainer>
        <Container size="sm">
          <TextContainer $visible={vText ? vText.toString() : undefined}>
            <Row>
              <Title xs={12}>{t('connect-your-wallet-title')}</Title>
            </Row>
            <Spacer />
            <Row center={'xs'}>
              <BetaWarning xs={6} />
            </Row>
            <Spacer />
            <Row center={'xs'}>
              <Col xs={5}>
                <Button
                  outline={false}
                  text={t('connect-your-wallet-action')}
                  onClick={handleUnlockClick}
                />
              </Col>
            </Row>
          </TextContainer>
        </Container>
      </Grid>
    </Content>
  );
};
