import { useState, useEffect } from 'react';
import BN from 'bignumber.js';
import { useAddress, useAbi } from './useAddress';
import { useWeb3 } from './useWeb3';

export const useAllocatedRLY = (name, block) => {
  const [events, setEvents] = useState(null);
  const web3 = useWeb3();
  const toAddress = useAddress(name);
  const rlyAddress = useAddress('RallyToken');
  const rlyAbi = useAbi('RallyToken');
  const [allocated, setAllocated] = useState(null);

  useEffect(() => {
    if (!web3) {
      return;
    }
    (async () => {
      const web3RLYInterface = new web3.eth.Contract(rlyAbi, rlyAddress);
      let pastEvents = await web3RLYInterface.getPastEvents('Transfer', {
        filter: {
          from: '0xFb467828778a6747011c467e1B346D08491Ae353',
          to: toAddress,
        },
        fromBlock: block,
      });
      setEvents(pastEvents);
    })();
  }, [rlyAddress, rlyAbi, block, web3, toAddress]);

  useEffect(() => {
    if (!events || events.length === 0) {
      return;
    }

    if (!BN(allocated).isZero() && !BN(allocated).isNaN()) {
      return;
    }

    const values = events.map(({ returnValues }) => returnValues.value);
    const value = values.reduce((acc, v) => acc.plus(BN(v)), BN(0));
    setAllocated(value);
  }, [events, allocated]);

  return allocated;
};
