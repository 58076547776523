import { useCallback, useContext } from 'react';

import { ModalsContext } from '../context';

const useModal = (modal, key) => {
  const { onDismiss, onPresent } = useContext(ModalsContext);
  const handlePresent = useCallback(() => {
    onPresent(modal, key);
  }, [key, modal, onPresent]);

  return [handlePresent, onDismiss];
};

export default useModal;
