export const TOKEN_TYPES = {
  UNDERLYING: 0,
  Y_TOKEN: 1,
};

export const getTokenContractName = (type, name) => {
  return type === TOKEN_TYPES.UNDERLYING ? name : `yToken${name}`.replace(/_V\d/, '');
};

export const getTokenVaultContractName = (name) => {
  return `yTokenVault${name}`.replace(/_V\d/, '');
};
