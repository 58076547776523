import React, { useMemo, useState, useCallback } from 'react';
import {
  StyledExternalLink,
  StyledLink,
  DisabledButton,
  OutlinedButton,
  GradientButton,
} from './styles';

const Button = ({
  className,
  children,
  href,
  onClick,
  text,
  to,
  disabled,
  async = false,
  outline = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleOnClick = useCallback(async () => {
    if (!async) {
      onClick();
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  }, [onClick, async, isLoading]);

  const ButtonChild = useMemo(() => {
    if (isLoading) {
      return <div className={'btn-loader'} />;
    }
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>;
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank" rel={'noopener noreferrer'}>
          {text}
        </StyledExternalLink>
      );
    } else {
      return text;
    }
  }, [href, text, to, isLoading]);

  if (disabled) {
    return (
      <DisabledButton className={className} disabled={disabled}>
        {ButtonChild}
        {children}
      </DisabledButton>
    );
  }

  const ButtonContainer = outline ? OutlinedButton : GradientButton;
  return (
    <ButtonContainer className={className} onClick={handleOnClick} disabled={disabled}>
      {ButtonChild}
      {children}
    </ButtonContainer>
  );
};

export default Button;
