import Web3 from 'web3';
import React, { useState, useEffect, useCallback } from 'react';
import { Drizzle } from '@drizzle/store';
import { useWallet } from 'use-wallet';
import ERC20 from '../contracts/ERC20';
import Vault from '../contracts/Vault';
import YieldDelegatingVault from '../contracts/YieldDelegatingVault';
import YieldDelegatingVault2 from '../contracts/YieldDelegatingVault2.json';
import NoMintLiquidityRewardPools from '../contracts/NoMintLiquidityRewardPools';
import BridgeContract from '../contracts/BridgeContract.json';

export const DrizzleContext = React.createContext();

// y Tokens addresses  for (deposityToken, withdrawyToken)
const Y_TOKEN_ADDRESSES = {
  V1: {
    USDC: '0x597ad1e0c13bfe8025993d9e79c69e1c0233522e',
    YCRV: '0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c',
    TUSD: '0x37d19d1c4E1fa9DC47bD1eA12f742a0887eDa74a',
    DAI: '0xACd43E627e64355f1861cEC6d3a6688B31a6F952',
    USDT: '0x2f08119C6f07c006695E079AAFc638b8789FAf18',
    YFI: '0xBA2E7Fed597fd0E3e70f5130BcDbbFE06bB94fe1',
    crvBUSD: '0x2994529C0652D127b7842094103715ec5299bBed',
    crvBTC: '0x7Ff566E1d69DEfF32a7b244aE7276b9f90e9D0f6',
    WETH: '0xe1237aA7f535b0CC33Fd973D66cBf830354D16c7',
  },
  V2: {
    CRV3: '0x9ca85572e6a3ebf24dedd195623f188735a5179f',
  },
};

// Loaded from migration addresses
const VAULT_ADDRESSES = {
  V1: {
    USDC: '0x399ccE5797C2Aa3eA96A72074e0626f92a96c1aD',
    YCRV: '0x0513125C4ffDF276c6Bb9B67DbB532a9BA9804eD',
    TUSD: '0x2b8f3badc786f38C5c44cA44541D1460b1c8468D',
    DAI: '0x83919C38950b21c8B5170f95Bb2D8Ca02CAefc51',
    USDT: '0xEaa827A144FeB4C4305c6f4bF7dF860c41575A9d',
    YFI: '0x16Ea160214795A67b66a82dbd45161b0b2cBE566',
    crvBUSD: '0x7992652e1C9bA634bC26B978a44F736C94120D06',
    crvBTC: '0x8F412B4822c593aDcc01B2708229c7cB26c35725',
    WETH: '0x2D433ED13adcBA003f80bEC3889403FCb0790A44',
  },
  V2: {
    USDC_V2: '0xd8884Af0aB922b55E34153b32f6da71356589869',
    YCRV_V2: '0xb13987Da63B7317BF98b2bFeF8C98F1f7D679BE2',
    DAI_V2: '0xA075fF50eCd39819B85f62dc4AAa047718688Bc8',
    YFI_V2: '0x039db4F0F712BeDA336cB22e48006F613e2F0969',
    crvBUSD_V2: '0x5B21C1C8192D47529dd33931027FA014fdd9e208',
    CRV3: '0x398f6c9e0a6490da189885283880be8df92bad36',
  },
};

const CONTRACT_LOADING_STATE = {
  NONE: 0,
  LOADING: 1,
  DONE: 2,
};

export const DrizzleProvider = ({ children }) => {
  const { ethereum, status } = useWallet();
  const [drizzle, setDrizzle] = useState(null);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingContracts, setLoadingContracts] = useState([]);
  const [loadingContractsState, setLoadingContractsState] = useState(
    CONTRACT_LOADING_STATE.NONE
  );
  const [initialized, setInitialized] = useState(false);

  const connect = useCallback(() => {
    const web3 = new Web3(ethereum);
    (async () => {
      setLoading(true);
      // let drizzle know what contracts we want and how to access our test blockchain
      const options = {
        web3: {
          customProvider: web3,
          fallback: {
            type: 'ws',
            url: 'ws://127.0.0.1:9545',
          },
        },
      };

      const loaded = new Drizzle(options);
      // setup the drizzle store and drizzle
      setDrizzle(loaded);
    })();
  }, [ethereum]);

  useEffect(() => {
    if (!drizzle) {
      return;
    }

    const unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();
      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        setState(drizzleState);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [drizzle]);

  useEffect(() => {
    if (
      (loading && !initialized) ||
      !drizzle ||
      initialized ||
      loadingContractsState !== CONTRACT_LOADING_STATE.NONE
    ) {
      return;
    }

    setLoadingContractsState(CONTRACT_LOADING_STATE.LOADING);
    let contracts = [
      {
        contractName: 'RallyToken',
        web3Contract: new drizzle.web3.eth.Contract(
          ERC20.abi,
          '0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b'
        ),
      },
      {
        contractName: 'USDCToken',
        web3Contract: new drizzle.web3.eth.Contract(
          ERC20.abi,
          '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
        ),
      },
      {
        contractName: 'WETHToken',
        web3Contract: new drizzle.web3.eth.Contract(
          ERC20.abi,
          '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
        ),
      },
      {
        contractName: 'CRV3Token',
        web3Contract: new drizzle.web3.eth.Contract(
          ERC20.abi,
          '0x398f6c9e0a6490da189885283880be8df92bad36'
        ),
      },
      {
        contractName: 'RallyBridge',
        web3Contract: new drizzle.web3.eth.Contract(
          BridgeContract.abi,
          '0xCc8F119E0Bb46EF52b2927CA1cfAE80B410Ef674'
        ),
      },
      // V1 Vault and token loading
      ...Object.entries(VAULT_ADDRESSES.V1).map(([key, value]) => ({
        contractName: `yVault${key}`,
        web3Contract: new drizzle.web3.eth.Contract(YieldDelegatingVault.abi, value),
      })),
      ...Object.entries(Y_TOKEN_ADDRESSES.V1).map(([key, value]) => ({
        contractName: `yToken${key}`,
        web3Contract: new drizzle.web3.eth.Contract(ERC20.abi, value),
      })),
      ...Object.entries(Y_TOKEN_ADDRESSES.V1).map(([key, value]) => ({
        contractName: `yTokenVault${key}`,
        web3Contract: new drizzle.web3.eth.Contract(Vault.abi, value),
      })),
      // V2 Vault and token loading
      ...Object.entries(VAULT_ADDRESSES.V2).map(([key, value]) => ({
        contractName: `yVault${key}`,
        web3Contract: new drizzle.web3.eth.Contract(YieldDelegatingVault2.abi, value),
      })),
      ...Object.entries(Y_TOKEN_ADDRESSES.V2).map(([key, value]) => ({
        contractName: `yToken${key}`,
        web3Contract: new drizzle.web3.eth.Contract(ERC20.abi, value),
      })),
      ...Object.entries(Y_TOKEN_ADDRESSES.V2).map(([key, value]) => ({
        contractName: `yTokenVault${key}`,
        web3Contract: new drizzle.web3.eth.Contract(Vault.abi, value),
      })),
      {
        contractName: 'NoMintLiquidityRewardPools',
        web3Contract: new drizzle.web3.eth.Contract(
          NoMintLiquidityRewardPools.abi,
          '0x9CF178df8DDb65B9ea7d4C2f5d1610eB82927230'
        ),
      },
    ];

    contracts.map((c) => {
      return drizzle.addContract(c);
    });

    (async () => {
      // Underlying token for (deposit, withdraw, withdrawAll)
      const tokens = await Promise.all(
        [...Object.keys(VAULT_ADDRESSES.V1), ...Object.keys(VAULT_ADDRESSES.V2)].map(
          async (key) => {
            // console.log(`Loading ${key}`);
            return new Promise(async (resolve) => {
              // console.log(drizzle.contracts[`yVault${key}`]);
              const tokenAddress = await drizzle.contracts[`yVault${key}`].methods
                .token()
                .call()
                .catch((e) => {
                  console.error('Opps', e);
                });

              // console.log(`[token-${key}] Loaded.`);

              resolve({
                contractName: key,
                web3Contract: new drizzle.web3.eth.Contract(ERC20.abi, tokenAddress),
              });
            });
          }
        )
      );

      const poolLength = await drizzle.contracts.NoMintLiquidityRewardPools.methods
        .poolLength()
        .call();

      const pools = await Promise.all(
        Array.from({ length: poolLength }).map(async (_v, i) => {
          return new Promise(async (resolve) => {
            const info = await drizzle.contracts.NoMintLiquidityRewardPools.methods
              .poolInfo(i)
              .call();

            resolve({
              contractName: `Pool_${i}`,
              web3Contract: new drizzle.web3.eth.Contract(ERC20.abi, info.lpToken),
            });
          });
        })
      );

      [...tokens, ...pools].map((c) => {
        return drizzle.addContract(c);
      });

      setLoadingContracts([...contracts, ...pools, ...tokens]);
      setLoadingContractsState(CONTRACT_LOADING_STATE.DONE);
    })();
  }, [drizzle, initialized, loading, loadingContracts, loadingContractsState]);

  useEffect(() => {
    if (
      !drizzle ||
      initialized ||
      loadingContractsState !== CONTRACT_LOADING_STATE.DONE ||
      loadingContracts.length === 0
    ) {
      return;
    }

    // This makes sure that all the contracts that we added are actually loaded
    setInitialized(drizzle.contractList.length === loadingContracts.length);
  }, [drizzle, initialized, loading, loadingContracts, loadingContractsState]);

  useEffect(() => {
    if (status !== 'connected') {
      return;
    }

    if (drizzle) {
      return;
    }

    // We should be connected, let's connect drizzle
    connect();
  }, [connect, status, drizzle]);

  return (
    <DrizzleContext.Provider value={{ loading, initialized, drizzle, state, connect }}>
      {children}
    </DrizzleContext.Provider>
  );
};
