import { useCallback, useMemo } from 'react';
import BN from 'bignumber.js';

import { useAllocatedRLY } from './useAllocatedRLY';
import { bnToDec } from '../utils/number';

export const useVaultAPY = (name, blocks, balance, priceRLY, priceToken) => {
  const vault = `yVault${name}`;
  const rly1days = useAllocatedRLY(vault, blocks['1']);
  const rly3days = useAllocatedRLY(vault, blocks['3']);
  const rly7days = useAllocatedRLY(vault, blocks['7']);
  const rly30days = useAllocatedRLY(vault, blocks['30']);

  const getAPY = useCallback(
    (days, allocated) => {
      if (!allocated || !days) {
        return '0.00';
      }

      const rlyValueOverXDays = BN(allocated).multipliedBy(priceRLY);
      return bnToDec(
        rlyValueOverXDays
          .dividedBy(BN(balance).multipliedBy(priceToken))
          .multipliedBy(365)
          .dividedBy(days)
          .multipliedBy(100)
      ).toFixed(2);
    },
    [priceRLY, priceToken, balance]
  );

  const apys = useMemo(() => {
    if (!name) {
      return {
        apy1Day: 0,
        apy3Days: 0,
        apy7Days: 0,
        apy30Days: 0,
      };
    }

    return {
      apy1Day: getAPY(1, rly1days),
      apy3Days: getAPY(3, rly3days),
      apy7Days: getAPY(7, rly7days),
      apy30Days: getAPY(30, rly30days),
    };
  }, [name, getAPY, rly1days, rly3days, rly7days, rly30days]);

  return apys;
};
