import React from 'react';

import { usePastBlocks } from '../hooks/usePastBlocks';
import { useTokenPrices } from '../hooks/useTokenPrices';

export const VaultsContext = React.createContext();

export const VaultsProvider = ({ children }) => {
  const blocks = usePastBlocks();
  const prices = useTokenPrices();
  return (
    <VaultsContext.Provider value={{ blocks, prices }}>{children}</VaultsContext.Provider>
  );
};
