import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-flexbox-grid';
import { Button } from '../components';
import { useTranslation } from 'react-i18next';

import Forms from '../assets/img/forms_M.svg';

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  color: #ffffff;
`;

const ImageContainer = styled(Row)`
  position: relative;
  background: #000000;
`;

const Image = styled.img``;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 2em;
}
`;

const Title = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 1rem;
`;

const FAQButton = styled(Button)`
  width: 50%;
  margin-top: 1em;
`;

const Mobile = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <ImageContainer center={'xs'}>
        <Image src={Forms} width={'100%'} alt={'Geometric Forms'} />
        <Content>
          <Title>Welcome to Rally Vaults</Title>
          <Description>
            Currently this site does not support mobile browsers. Please use a desktop
            browser to access Rally Yield Delegating Vaults and Liquidity Mining.
          </Description>{' '}
          <FAQButton to={'/faq'} text={t('nav-faq')} outline={false} />
        </Content>
      </ImageContainer>
    </PageContainer>
  );
};

export default Mobile;
