import styled from 'styled-components';
import { Card, Button } from '../index';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { fadeIn, fadeOut } from '../../theme/animations';

export const StyledCard = styled(Card)`
  width: 100%;
  background-color: #4d4d4d;
  margin-bottom: 1em;
  border-radius: 17px;
  z-index: 1;
  font-size: 16px;

  .accordion-top {
    min-height: 120px;
    border-radius: 16px;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.23);
    background-image: linear-gradient(93deg, #aa3209 21%, #b29302 103%);
  }

  visibility: ${({ $visible }) => (!$visible ? 'hidden' : 'visible')};
  animation: ${({ $visible }) => (!$visible ? fadeOut : fadeIn)} 1s linear;
  transition: visibility 1s linear;
`;

export const StyledInput = styled.input`
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  padding-left: 1em;
  text-align: left;
  height: 40px;
  color: #4d4d4d;
  width: 100%;
`;

export const AccordionContent = styled(Grid)``;

export const WalletBalance = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  padding-left: 10px;
`;

export const BalanceRow = styled(Row)`
  justify-content: space-between;
`;

export const ContainerCol = styled(Col)`
  margin-top: 1em;
  margin-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
`;

export const Earnings = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TokenLogo = styled.img`
  height: 25px;
  width: 25px;
  padding-left: 5px;
`;

export const Maximum = styled(Col)`
  font-size: 14px;

  ${({ $active }) =>
    $active &&
    `
    cursor: pointer;
    color: rgba(255,255,255,1);
    &:hover {
      color: #ffd800;
    };
  `}
`;

export const ApprovalButton = styled(Button)`
  height: 40px;
  font-weight: normal;
`;

export const Disclaimer = styled(Col)``;
