import { useCallback, useEffect, useState } from 'react';
import { useMethods } from './useMethods';

export const useCall = (
  name,
  method,
  defaultValue = null,
  params = null,
  block = null,
  interval = null
) => {
  const cMethods = useMethods(name);
  const [value, setValue] = useState(defaultValue);

  const execute = useCallback(async () => {
    if (!cMethods) {
      return;
    }
    let executor = cMethods[method];
    try {
      executor =
        params !== null
          ? executor(...(Array.isArray(params) ? params : [params]))
          : executor();

      const result = await (block !== null ? executor.call({}, block) : executor.call());
      setValue(result);
    } catch (e) {
      // console.error(e);
      return;
    }
  }, [cMethods, method, params, block]);

  useEffect(() => {
    if (!cMethods) {
      return;
    }

    execute();
    if (interval) {
      let refreshInterval = setInterval(execute, interval);
      return () => clearInterval(refreshInterval);
    }
  }, [cMethods, execute, interval]);

  return [value, execute];
};

export const useCallWithMultipleParams = (
  name,
  method,
  defaultValue = null,
  param1 = null,
  param2 = null,
  interval = null
) => {
  const cMethods = useMethods(name);
  const [value, setValue] = useState(defaultValue);

  const execute = useCallback(async () => {
    if (!cMethods) {
      return;
    }

    try {
      const result = await cMethods[method](param1, param2).call();
      setValue(result);
    } catch (e) {
      console.error(e);
    }
  }, [cMethods, method, param1, param2]);

  useEffect(() => {
    if (!cMethods) {
      return;
    }

    execute();
    if (interval) {
      let refreshInterval = setInterval(execute, interval);
      return () => clearInterval(refreshInterval);
    }
  }, [cMethods, execute, interval]);

  return [value, execute];
};
