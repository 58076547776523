import React, { useState, useEffect, useCallback, useContext } from 'react';
import BN from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useWallet } from 'use-wallet';

import { Container } from '../components';
import { useWeb3 } from '../hooks/useWeb3';
import { useToken } from '../hooks/useToken';
import { useVaults } from '../hooks/useVaults';
import { useMethods, usePoolMethods } from '../hooks/useMethods';
import { usePoolPrices } from '../hooks/usePool';
import { useAddress, useAbi } from '../hooks/useAddress';
import { useVaultPrices } from '../hooks/useVault';
import { POOLS, VAULTS } from '../data';
import { bnToDec, getFormatedValue } from '../utils/number';
import { getTokenVaultContractName } from '../utils/token';
import { VaultsContext } from '../context';

import Forms from '../assets/img/forms.svg';
import RLY from '../assets/img/RLY-logo.png';

const StyledContainer = styled(Container)`
  padding-top: 2em;
  position: relative;
  z-index: 0;
`;

const ImageContainer = styled(Row)`
  position: relative;
  opacity: 0.5;
`;

const Image = styled.img`
  z-index: 0;
  position: absolute;
`;

const Title = styled(Col)`
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding-bottom: 20px;
`;

const Claimable = styled(Row)`
  border: solid 1px #ffd800;
  background-color: rgba(255, 216, 0, 0.2);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  min-height: 200px;
  padding: 30px 40px;

  margin-top: 0.5em;
`;

const Totals = styled(Row)`
  border: solid 1px #ffd800;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  min-height: 235px;
  padding: 30px 40px;

  margin-bottom: 0.5em;
`;

const Deposited = styled(Row)`
  border: solid 1px #ffd800;
  border-radius: 16px;
  min-height: 435px;
  padding: 20px;
  margin: 0.5em 0;
`;
const Staked = Deposited;

const DepositedTotals = styled(Col)`
  padding-left: 24px;
`;
const StakedTotals = DepositedTotals;

const TotalDepositedValue = styled(Col)`
  font-family: SofiaPro-Light;
  font-size: 24px;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: right;
  color: #ffd800;
`;
const TotalStakedValue = TotalDepositedValue;

const DepositedTitle = styled(Col)`
  font-family: SofiaPro-Light;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  padding-right: 24px;
`;

const StakedTitle = DepositedTitle;

const Spacer = styled(Row)`
  padding: ${(props) => (props.space || 1) * 1}em 0;
`;

const RLYLogo = styled.img`
  width: 42px;
  height: 42px;
`;

const RLYText = styled.div`
  font-family: SofiaPro-Regular;
  font-size: 35px;
  font-weight: bold;
  line-height: 1.2;
  padding-left: 13px;
`;

const ClaimableTitle = styled(Col)`
  text-align: right;
  font-family: SofiaPro-Light;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
`;

const ClaimableValue = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  font-size: 80px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
`;

const RLYContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

const TotalContainer = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const TotalDescription = styled.div`
  font-family: SofiaPro-Light;
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
`;

const TotalValue = styled.div`
  font-family: SofiaPro-Light;
  font-size: 24px;
  line-height: 1.5;
  text-align: right;
`;

const VaultInfo = styled(Col)`
  display: flex;
  align-items: center;
`;

const VaultLogo = styled.img`
  width: 28px;
  height: 28px;
`;

const LiquidityLogo = styled.img`
  padding: 3px;
  width: 24px;
  height: 24px;
  padding-right: 6px;
`;

const VaultTitle = styled(Col)`
  font-family: SofiaPro-Light;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-left: 11px;
`;

const VaultDepositedContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

const VaultValue = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
`;

const LiquidityTitle = styled(Col)`
  font-family: SofiaPro-Light;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const LiquidityInfo = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const LiquidityPrices = styled(Col)``;

const LiquidityStaked = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LiquidityStakedToken = styled.div`
  display: flex;
  align-item: center;
`;

const LiquidityStakedValue = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
`;

const FullRow = styled(Row)`
  width: 100%;
`;

export const useLiquidityTotals = (defaultValue = null, interval = null) => {
  const name = 'NoMintLiquidityRewardPools';
  const { account } = useWallet();
  const cMethods = useMethods(name);
  const pMethods = usePoolMethods();
  const RLYMethods = useMethods('RallyToken');

  const web3 = useWeb3();
  const rlyAddress = useAddress('RallyToken');
  const rlyAbi = useAbi('RallyToken');

  const [earned, setEarned] = useState(defaultValue);
  const [allocated, setAllocated] = useState(defaultValue);
  const [rlyBalance, setRLYBalance] = useState(defaultValue);
  const [lastClaim, setLastClaim] = useState(null);

  const getRLYBalance = useCallback(
    async (index) => {
      if (!cMethods || !RLYMethods || !pMethods) {
        return 0;
      }

      const { address, isVault } = POOLS[index];
      if (isVault) {
        return BN(0);
      }

      const balanceRLY = await RLYMethods['balanceOf'](address).call();
      const totalSupply = await pMethods[index]['totalSupply']().call();
      const { amount } = await cMethods['userInfo'](index, account).call();

      const percentage = bnToDec(
        BN(amount).dividedBy(BN(totalSupply)).multipliedBy(BN('100e+18'))
      );

      return BN(balanceRLY).multipliedBy(percentage).dividedBy(100);
    },
    [RLYMethods, account, cMethods, pMethods]
  );

  const getEarned = useCallback(
    async (index) => {
      if (!cMethods) {
        return 0;
      }

      try {
        const result = await cMethods['pendingRally'](index, account).call();
        return result;
      } catch (e) {
        console.error(e);
        return -1;
      }
    },
    [cMethods, account]
  );

  const getAllocatedEvents = useCallback(async () => {
    if (!web3) {
      return 0;
    }

    const web3RLYInterface = new web3.eth.Contract(rlyAbi, rlyAddress);
    let events = await web3RLYInterface.getPastEvents('Transfer', {
      filter: {
        from: '0x9CF178df8DDb65B9ea7d4C2f5d1610eB82927230',
        to: account,
      },
      fromBlock: 0,
    });

    return events;
  }, [rlyAddress, rlyAbi, web3, account]);

  const execute = useCallback(async () => {
    let value = await Promise.all(POOLS.map((_, i) => getEarned(i)));
    let total = value.reduce((acc, v) => BN(acc).plus(BN(v)), 0);
    setEarned(getFormatedValue(total));

    value = await Promise.all(POOLS.map((_, i) => getRLYBalance(i)));
    total = value.reduce((acc, v) => BN(acc).plus(BN(v)), 0);
    setRLYBalance(getFormatedValue(total));

    const events = await getAllocatedEvents();
    if (!events || events.length === 0) {
      return;
    }

    const values = events.map(({ returnValues }) => returnValues.value);
    total = values.reduce((acc, v) => acc.plus(BN(v)), BN(0));

    const date = (await web3.eth.getBlock(events[events.length - 1].blockNumber))
      .timestamp;

    setLastClaim({
      value: getFormatedValue(BN(values[values.length - 1])),
      date: new Date(date * 1000).toDateString(),
    });
    setAllocated(getFormatedValue(total));
  }, [getEarned, getAllocatedEvents, getRLYBalance, web3]);

  useEffect(() => {
    if (!cMethods) {
      return;
    }

    execute();
    if (interval) {
      let refreshInterval = setInterval(execute, interval);
      return () => clearInterval(refreshInterval);
    }
  }, [cMethods, execute, interval]);

  return [earned, allocated, lastClaim, rlyBalance];
};

export const useVaultsTotals = (defaultValue = null, interval = null) => {
  const { account } = useWallet();
  const vaults = useVaults(VAULTS.V2);
  const { prices } = useContext(VaultsContext);

  const web3 = useWeb3();
  const rlyAddress = useAddress('RallyToken');
  const rlyAbi = useAbi('RallyToken');

  const [earned, setEarned] = useState(defaultValue);
  const [allocated, setAllocated] = useState(defaultValue);
  const [totalDeposited, setTotalDeposited] = useState(defaultValue);
  const [lastClaim, setLastClaim] = useState(null);

  const getEarned = useCallback(
    async (name) => {
      if (!vaults || !vaults[name] || !vaults[name].methods) {
        return 0;
      }

      try {
        const result = await vaults[name].methods['earned'](account).call();
        return result;
      } catch (e) {
        console.error(e);
        return -1;
      }
    },
    [vaults, account]
  );

  const getAllocatedEvents = useCallback(
    async (name) => {
      if (!web3 || !vaults || !vaults[name] || !vaults[name].address) {
        return 0;
      }

      const web3RLYInterface = new web3.eth.Contract(rlyAbi, rlyAddress);
      let events = await web3RLYInterface.getPastEvents('Transfer', {
        filter: {
          from: vaults[name].address,
          to: account,
        },
        fromBlock: 0,
      });

      return events;
    },
    [rlyAddress, rlyAbi, web3, account, vaults]
  );

  const getDepositedValue = useCallback(
    async (name, priceKey) => {
      const decimals = await vaults[name].methods['decimals']().call();
      const vBalance = await vaults[name].methods['balance']().call();
      const aBalance = await vaults[name].methods['balanceOf'](account).call();
      const vTotalSupply = await vaults[name].methods['totalSupply']().call();
      const pricePerFullShare = await vaults[getTokenVaultContractName(name)].methods[
        'getPricePerFullShare'
      ]().call();

      let value = BN(vBalance).multipliedBy(aBalance);

      if (decimals < 18) {
        value = value.multipliedBy(`1e+${18 - decimals}`);
      }

      value = value
        .dividedBy(BN(vTotalSupply))
        .multipliedBy(BN(pricePerFullShare))
        .dividedBy(BN('1e+18'))
        .multipliedBy(BN(prices[priceKey].usd));

      return value;
    },
    [account, vaults, prices]
  );

  const execute = useCallback(async () => {
    let value = await Promise.all(VAULTS.V2.map((v) => getEarned(v.name)));
    let total = value.reduce((acc, v) => BN(acc).plus(BN(v)), 0);
    setEarned(getFormatedValue(total));

    value = await Promise.all(
      VAULTS.V2.map((v) => getDepositedValue(v.name, v.priceKey))
    );
    total = value.reduce((acc, v) => BN(acc).plus(BN(v)), 0);
    setTotalDeposited(bnToDec(total).toFixed(2));

    const events = (
      await Promise.all(VAULTS.V2.map((v) => getAllocatedEvents(v.name)))
    ).flat();
    const values = events.map(({ returnValues }) => returnValues.value);
    total = values.reduce((acc, v) => acc.plus(BN(v)), BN(0));
    setAllocated(getFormatedValue(total));

    events.sort((a, b) => a.blockNumber - b.blockNumber);
    const last = events[events.length - 1];
    const date = (await web3.eth.getBlock(last?.blockNumber)).timestamp;
    setLastClaim({
      value: getFormatedValue(BN(last?.returnValues.value)),
      date: new Date(date * 1000).toDateString(),
    });
  }, [getEarned, getAllocatedEvents, getDepositedValue, web3]);

  useEffect(() => {
    if (!vaults) {
      return;
    }

    execute();
    if (interval) {
      let refreshInterval = setInterval(execute, interval);
      return () => clearInterval(refreshInterval);
    }
  }, [vaults, execute, interval]);

  return [earned, allocated, lastClaim, totalDeposited];
};

const VaultsRow = ({ logo, name, priceKey }) => {
  const v = useVaultPrices(name, priceKey);
  const token = useToken(name);

  return (
    <VaultDepositedContainer md={12}>
      <FullRow>
        <VaultInfo md={6} sm={12}>
          <VaultLogo src={logo} />
          <VaultTitle>{token.title}</VaultTitle>
        </VaultInfo>
        <VaultValue md={6} sm={12}>
          ${bnToDec(v.value).toFixed(2)}
        </VaultValue>
      </FullRow>
    </VaultDepositedContainer>
  );
};

const LiquidityRow = ({ logos, subtitle, title, address, index, vault, isVault }) => {
  const price = usePoolPrices(index, address, vault, isVault);
  return (
    <VaultDepositedContainer md={12}>
      <FullRow>
        <LiquidityInfo xs={12}>
          <LiquidityTitle>
            {title} {subtitle}
          </LiquidityTitle>
          <LiquidityStakedValue>
            ${price.value} | {price.percentage}%
          </LiquidityStakedValue>
        </LiquidityInfo>
        <LiquidityPrices xs={12}>
          <LiquidityStaked>
            <LiquidityStakedToken>
              {logos[0] && (
                <>
                  <LiquidityLogo key={logos[0]} src={logos[0]} alt={'token logo'} />
                  {price.rly}
                </>
              )}
            </LiquidityStakedToken>
            {logos[1] && (
              <LiquidityStakedToken>
                <LiquidityLogo key={logos[1]} src={logos[1]} alt={'token logo'} />
                {price.token}
              </LiquidityStakedToken>
            )}
          </LiquidityStaked>
        </LiquidityPrices>
      </FullRow>
    </VaultDepositedContainer>
  );
};

const Vaults = ({ data }) =>
  data.map((v, i) => <VaultsRow {...v} key={`vault-${i}-${v}`} />);

const Liquidity = ({ data }) =>
  data.map((v, i) => <LiquidityRow {...v} key={`liquidity-${i}-${v}`} index={i} />);

const Dashboard = () => {
  const { t } = useTranslation();
  const [lTotalEarned, lTotalAllocated, lLastClaimValue, lTotalRLYStaked] =
    useLiquidityTotals(0, 1000 * 15);
  const [vTotalEarned, vTotalAllocated, vLastClaimValue, vTotalDeposited] =
    useVaultsTotals(0, 1000 * 15);

  return (
    <Container size={'lg'}>
      <ImageContainer center={'xs'}>
        <Image src={Forms} width={'100%'} alt={'Geometric Forms'} />
      </ImageContainer>
      <StyledContainer size={'lg'}>
        <Grid fluid>
          <Col md={12}>
            <Row>
              <Title md={12}>{t('dashboard-vaults')}</Title>
            </Row>
            <Row center={'xs'}>
              <Col md={7} sm={12}>
                <Claimable>
                  <Col md={12}>
                    <Row>
                      <ClaimableTitle md={12}>Claimable</ClaimableTitle>
                    </Row>
                    <Row>
                      <RLYContainer lg={4} md={12} sm={12}>
                        <RLYLogo src={RLY} />
                        <RLYText>RLY</RLYText>
                      </RLYContainer>
                      <ClaimableValue lg={8} md={12} sm={12}>
                        {vTotalEarned}
                      </ClaimableValue>
                    </Row>
                  </Col>
                </Claimable>
                <Totals>
                  <TotalContainer xs={12}>
                    <TotalDepositedValue>Total value deposited: </TotalDepositedValue>
                    <TotalDepositedValue>${vTotalDeposited}</TotalDepositedValue>
                  </TotalContainer>
                  <TotalContainer xs={12}>
                    <TotalDescription>My RLY claimed to date:</TotalDescription>
                    <TotalValue>{vTotalAllocated}</TotalValue>
                  </TotalContainer>
                  <TotalContainer xs={12}>
                    <TotalDescription>Most Recent Claim:</TotalDescription>
                    <TotalValue>
                      {vLastClaimValue
                        ? `${vLastClaimValue.value} RLY on ${vLastClaimValue.date}`
                        : 'No data'}
                    </TotalValue>
                  </TotalContainer>
                </Totals>
              </Col>
              <DepositedTotals md={5} sm={12}>
                <Deposited>
                  <DepositedTitle md={12}>Deposited</DepositedTitle>
                  <Vaults data={VAULTS.V2} />
                </Deposited>
              </DepositedTotals>
            </Row>
            <Spacer />
            <Row>
              <Title md={12}>{t('dashboard-liquidity')}</Title>
            </Row>
            <Row center={'xs'}>
              <Col md={7} sm={12}>
                <Claimable>
                  <Col md={12}>
                    <Row>
                      <ClaimableTitle md={12}>Claimable</ClaimableTitle>
                    </Row>
                    <Row>
                      <RLYContainer lg={4} md={12} sm={12}>
                        <RLYLogo src={RLY} />
                        <RLYText>RLY</RLYText>
                      </RLYContainer>
                      <ClaimableValue lg={8} md={12} sm={12}>
                        {lTotalEarned}
                      </ClaimableValue>
                    </Row>
                  </Col>
                </Claimable>
                <Totals>
                  <TotalContainer xs={12}>
                    <TotalStakedValue>Total RLY staked:</TotalStakedValue>
                    <TotalStakedValue>{lTotalRLYStaked}</TotalStakedValue>
                  </TotalContainer>
                  <TotalContainer xs={12}>
                    <TotalDescription>My RLY claimed to date:</TotalDescription>
                    <TotalValue>{lTotalAllocated}</TotalValue>
                  </TotalContainer>
                  <TotalContainer xs={12}>
                    <TotalDescription>Most Recent Claim:</TotalDescription>
                    <TotalValue>
                      {lLastClaimValue
                        ? `${lLastClaimValue.value} RLY on ${lLastClaimValue.date}`
                        : 'No data'}
                    </TotalValue>
                  </TotalContainer>
                </Totals>
              </Col>
              <StakedTotals md={5} sm={12}>
                <Staked>
                  <StakedTitle md={12}>Staked</StakedTitle>
                  <Liquidity data={POOLS} />
                </Staked>
              </StakedTotals>
            </Row>
          </Col>
        </Grid>
      </StyledContainer>
    </Container>
  );
};

export default Dashboard;
