import React from 'react';
import { useWallet } from 'use-wallet';
import { Route } from 'react-router-dom';
import { ConnectYourWallet } from '../ConnectYourWallet';

import Mobile from '../../pages/Mobile';

import { isMobile } from 'react-device-detect';

export const PrivateRoute = (props) => {
  const { account } = useWallet();

  if (isMobile) {
    return <Mobile />;
  }

  if (!account) {
    return <ConnectYourWallet />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
