import React, { useCallback, useState } from 'react';

export const TransactionContext = React.createContext();

export const TransactionProvider = ({ children }) => {
  const [count, setCount] = useState(0);

  const add = useCallback(() => {
    setCount((c) => c + 1);
  }, [setCount]);

  const remove = useCallback(() => {
    setCount((c) => c - 1);
  }, [setCount]);

  return (
    <TransactionContext.Provider value={{ count, add, remove }}>
      {children}
    </TransactionContext.Provider>
  );
};
