import { useContext, useState, useEffect } from 'react';
import { DrizzleContext } from '../context/DrizzleContext';

const AVG_BLOCK_TIME = 13;

export const useBlockAt = (days) => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!drizzle || !initialized || block !== null) {
      return;
    }

    (async () => {
      const { web3 } = drizzle;
      const span = Math.round((86400 * days) / AVG_BLOCK_TIME);
      const currentNumber = await web3.eth.getBlockNumber();
      setBlock(currentNumber - span);
    })();
  }, [block, drizzle, initialized, days]);

  return block;
};

export const usePastBlocks = () => {
  const block1Day = useBlockAt(1);
  const block3Day = useBlockAt(3);
  const block7Day = useBlockAt(7);
  const block30Day = useBlockAt(30);

  return {
    1: block1Day,
    3: block3Day,
    7: block7Day,
    30: block30Day,
  };
};
