import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Container, Button } from '../components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Vault from '../components/Vault';
import CloseWarning from '../components/CloseWarning';
import { VAULTS } from '../data';

import Forms from '../assets/img/forms.svg';

const Content = styled(Container)`
  position: relative;
  z-index: 0;
`;

const ImageContainer = styled(Row)`
  position: relative;
  opacity: 0.5;
`;

const Image = styled.img`
  z-index: 0;
  position: absolute;
`;

const ToggleContainer = styled(Col)`
  padding: 0em;
  margin: 1em 0em;
`;

const LeftButton = styled(Button)`
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  height: 40px;
`;

const RightButton = styled(Button)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 40px;
`;

const VaultContainer = styled(Col)`
  z-index: 1;
  ${({ $visible }) => !$visible && 'display: none;'}
`;

const Spacer = styled(Row)`
  padding: ${(props) => (props.space || 1) * 1}em 0;
`;

const VaultsCard = ({ vaults, $visible }) => {
  const vaultsList = vaults.map((v, i) => (
    <VaultContainer xs={12} key={`vault-${i}-${v}`} $visible={$visible}>
      <Vault {...v} fadeTime={i * 100} />
    </VaultContainer>
  ));

  return vaultsList;
};

const Vaults = () => {
  const [version, setVersion] = useState(2);
  const onVersionSelect = useCallback(
    (selected) => () => {
      setVersion(selected);
    },
    [setVersion]
  );

  return (
    <Content size={'lg'}>
      <ImageContainer center={'xs'}>
        <Image src={Forms} width={'100%'} alt={'Geometric Forms'} />
      </ImageContainer>
      <Container size={'md'}>
        <Grid fluid>
          <Row center={'xs'}>
            <CloseWarning xs={8} />
          </Row>
          <Spacer />
          <Row>
            <ToggleContainer xs={6}>
              <LeftButton
                outline={version !== 1}
                onClick={onVersionSelect(1)}
                text={'Legacy (v1)'}
              />
            </ToggleContainer>
            <ToggleContainer xs={6}>
              <RightButton
                outline={version !== 2}
                onClick={onVersionSelect(2)}
                text={'Active (v2)'}
              />
            </ToggleContainer>
          </Row>
          <Row center={'xs'}>
            <VaultsCard vaults={VAULTS.V1} $visible={version === 1} />
            <VaultsCard vaults={VAULTS.V2} $visible={version === 2} />
          </Row>
        </Grid>
      </Container>
    </Content>
  );
};

export default Vaults;
