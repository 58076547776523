import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonBase = styled.button`
  position: relative;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
`;

export const OutlinedButton = styled(ButtonBase)`
  border-radius: 20px;
  height: 40px;
  border: solid 1px #ffd800;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  :focus {
    outline: 0px;
    border: solid 2px #ffd800;
  }
`;

export const GradientButton = styled(ButtonBase)`
  height: 48px;
  border-radius: 24px;
  background-image: linear-gradient(103deg, #f1480b, #ffd800);
  font-weight: bold;
  border: 0px;
  cursor: pointer;

  :focus {
    outline: 0px;
    border: solid 2px #ffffff;
  }
`;

export const DisabledButton = styled(ButtonBase)`
  border-radius: 20px;
  height: 40px;
  color: rgba(255, 255, 255, 0.5);
  border: solid 1px rgba(255, 255, 255, 0.15);
  background-color: rgba(0, 0, 0, 0);

  :focus {
    outline: 0px;
  }
`;

export const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  text-decoration: none;
`;

export const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${({ theme }) => theme.spacing[4]}px;
  text-decoration: none;
`;
