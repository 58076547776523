import { useContext, useCallback } from 'react';
import { useWallet } from 'use-wallet';
import { decToBn } from '../utils/number';
import { useMethods } from './useMethods';
import { TransactionContext } from '../context/TransactionContext';

export const useDeposit = (name, method = 'deposit') => {
  const { account } = useWallet();
  const cMethods = useMethods(name);
  const { add, remove } = useContext(TransactionContext);

  const deposit = useCallback(
    async (amount, decimals, extraParams = []) => {
      // If ever we still have decimals after applying the decimals
      // we need to get rid of them.
      try {
        add();
        const value = decToBn(amount, decimals).toFixed(0, 1);
        let executor = cMethods[method];
        executor =
          extraParams !== null
            ? executor(
                value.toString(),
                ...(Array.isArray(extraParams) ? extraParams : [extraParams])
              )
            : executor(value.toString());

        const gas = await executor.estimateGas({
          from: account,
        });

        const tx = await executor.send({
          from: account,
          gas,
        });
        return tx.status;
      } finally {
        remove();
      }
    },
    [account, method, cMethods, add, remove]
  );

  return deposit;
};
