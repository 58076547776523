import { useContext, useState, useEffect } from 'react';
import { DrizzleContext } from '../context/DrizzleContext';

export const useWeb3 = () => {
  const { drizzle, initialized } = useContext(DrizzleContext);
  const [web3, setWeb3] = useState(null);

  useEffect(() => {
    if (!drizzle || !initialized || web3) {
      return;
    }

    setWeb3(drizzle.web3);
  }, [drizzle, initialized, web3]);

  return web3;
};
