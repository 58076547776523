import React, { useContext } from 'react';
import styled from 'styled-components';
import { BrowserView, MobileView } from 'react-device-detect';
import { TransactionContext } from '../../context/TransactionContext';
import { Container } from '../index';
import AccountButton from './components/AccountButton';
import Nav from './components/Nav';
import { Row, Grid, Col } from 'react-flexbox-grid';
import { fadeIn, fadeOut } from '../../theme/animations';

import TransactionIcon from '../../assets/img/transaction.svg';
import { ReactComponent as Logo } from '../../assets/img/rly-network-logo.svg';

const TopBar = () => {
  const { count } = useContext(TransactionContext);
  const hasPending = Number(count) > 0;
  return (
    <Container size="xl">
      <TopBarWrapper>
        <Grid>
          <BrowserView>
            <CenteredRow>
              <Col xs={2}>
                <a
                  href={'https://rly.network'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <Logo style={{ textColor: 'white' }} />
                </a>
              </Col>
              <Col xs={8}>
                <Nav />
              </Col>
              <Col xs={2}>
                <RightHeaderRow>
                  <TransactionPendingWrapper $visible={hasPending}>
                    <img
                      src={TransactionIcon}
                      className="spinner"
                      alt="Transaction Spinner Icon"
                    />
                    <CountWrapper>{count}&nbsp;Pending</CountWrapper>
                  </TransactionPendingWrapper>
                  <Col xs={12}>
                    <StyledAccountButtonWrapper>
                      <AccountButton />
                    </StyledAccountButtonWrapper>
                  </Col>
                </RightHeaderRow>
              </Col>
            </CenteredRow>
          </BrowserView>
          <MobileView>
            <CenteredRow>
              <a
                href={'https://rly.network'}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                <Logo fill="white" />
              </a>
            </CenteredRow>
          </MobileView>
        </Grid>
      </TopBarWrapper>
    </Container>
  );
};

const CenteredRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopBarWrapper = styled.div`
  padding: 1em 0;
  width: 100%;
`;

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 400px) {
    justify-content: center;
    width: auto;
  }
`;

const RightHeaderRow = styled(Row)`
  position: relative;
`;

const TransactionPendingWrapper = styled.div`
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: columns;
  left: -60%;
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  animation: ${({ $visible }) => (!$visible ? fadeOut : fadeIn)} 1s linear forwards;
`;

const CountWrapper = styled.div`
  padding-left: 0.5em;
`;

export default TopBar;
