import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Button } from '../index';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { bnToDec, bnToString } from '../../utils/number';

import RLY from '../../assets/img/RLY-logo.png';

const WalletBalance = styled.div`
  font-family: 'Roboto Mono', monospace;
  padding-left: 10px;
`;

const Balance = styled(Row)`
  font-family: 'Roboto Mono', monospace;
`;

const Percentage = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);

  ${({ $active }) =>
    $active &&
    `
    cursor: pointer;
    color: rgba(255,255,255,1);
    &:hover {
      color: #ffd800;
    };
  `}
`;

const PercentageRow = styled(Row)`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const TokenSelector = styled(Select)`
  width: 100%;
  color: #000000;
  text-align: left;
`;

const ContainerCol = styled(Col)`
  margin-top: 1em;
  margin-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
`;

const Earnings = styled(Col)`
  font-family: 'Roboto Mono', monospace;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const RLYLogo = styled.img`
  height: 25px;
  width: 25px;
  padding-left: 5px;
`;

const StyledInput = styled.input`
  font-family: 'Roboto Mono', monospace;
  font-size: 16px;
  padding-left: 1em;
  text-align: left;
  height: 40px;
  color: #4d4d4d;
  width: 100%;
`;

const Disclaimer = styled(Col)`
  padding-top: 0.75em;
`;

const AccordionContent = styled(Grid)``;

const ApprovalButton = styled(Button)`
  height: 40px;
  font-weight: normal;
`;

const VaultContent = ({
  vaultBalance,
  vaultDecimals,
  vaultEarned,
  vaultFormatedBalance,
  vaultSymbol,
  fetchBalance,
  claimRewards,
  options,
  withdraws,
  deposits,
  activeToken,
  onActiveTokenChange,
}) => {
  const { t } = useTranslation();
  const [dAmount, setDAmount] = useState(0); // Deposit
  const [wAmount, setWAmount] = useState(0); // Withdrawal

  const onWithdrawPercentageClick = useCallback(
    (percentage) => () => {
      setWAmount(
        bnToString(
          percentage !== 1 ? vaultBalance * percentage : vaultBalance,
          vaultDecimals
        )
      );
    },
    [vaultBalance, vaultDecimals]
  );

  const onDepositPercentageClick = useCallback(
    (percentage) => () => {
      if (!activeToken.isApproved) {
        return;
      }

      setDAmount(
        bnToString(
          percentage !== 1 ? activeToken.balance * percentage : activeToken.balance,
          activeToken.decimals
        )
      );
    },
    [activeToken]
  );

  const onWithdrawValueChange = useCallback(
    (e) => {
      setWAmount(e.target.value);
    },
    [setWAmount]
  );

  const onDepositValueChange = useCallback(
    (e) => {
      setDAmount(e.target.value);
    },
    [setDAmount]
  );

  const onApprove = useCallback(async () => {
    await activeToken.callApprove();
  }, [activeToken]);

  const onDeposit = useCallback(async () => {
    await activeToken.callDeposit(dAmount);
    fetchBalance();
    setDAmount(0);
  }, [activeToken, dAmount, fetchBalance]);

  const onWithdraw = useCallback(async () => {
    await activeToken.callWithdraw(wAmount);
    fetchBalance();
    setWAmount(0);
  }, [activeToken, fetchBalance, wAmount]);

  const onWithdrawAll = useCallback(async () => {
    await activeToken.callWithdraw(bnToDec(vaultBalance, vaultDecimals));
    fetchBalance();
    setWAmount(0);
  }, [activeToken, fetchBalance, vaultBalance, vaultDecimals]);

  const onTokenChange = useCallback(
    (o) => {
      setWAmount(0);
      setDAmount(0);
      onActiveTokenChange(o);
    },
    [onActiveTokenChange]
  );

  return (
    <AccordionContent>
      <Row>
        <ContainerCol xs={6}>
          <Row>{t('vault-active')}:</Row>
          <Row>
            <TokenSelector
              defaultValue={options[0]}
              name={t('vault-active')}
              options={options}
              onChange={onTokenChange}
            />
          </Row>
        </ContainerCol>
        <ContainerCol xs={6}>
          <Row>{t('vault-earnings')}:</Row>
          <Row>
            <Earnings xs={6}>
              <div>{Number(bnToDec(vaultEarned, 18)).toFixed(2)} RLY</div>
              <RLYLogo src={RLY} />
            </Earnings>
            <Col xs={6}>
              <Button onClick={claimRewards} text={t('vault-claim')} async={true} />
            </Col>
          </Row>
        </ContainerCol>
      </Row>
      <Row>
        <ContainerCol xs={6}>
          <Row>
            <div>{t('vault-your-wallet')}:</div>
            <WalletBalance>
              {activeToken.getFormatedBalance()} {activeToken.symbol}
            </WalletBalance>
          </Row>
          <Row>
            <StyledInput
              value={dAmount}
              disabled={(deposits && !activeToken.isApproved) || !deposits}
              onChange={onDepositValueChange}
              onBlur={onDepositValueChange}
              type="number"
              required
              min={0}
              max={activeToken.balance}
            />
          </Row>
          <PercentageRow around={'xs'}>
            <Percentage
              $active={activeToken.isApproved && deposits}
              onClick={onDepositPercentageClick(0.25)}
            >
              25%
            </Percentage>
            <Percentage
              $active={activeToken.isApproved && deposits}
              onClick={onDepositPercentageClick(0.5)}
            >
              50%
            </Percentage>
            <Percentage
              $active={activeToken.isApproved && deposits}
              onClick={onDepositPercentageClick(0.75)}
            >
              75%
            </Percentage>
            <Percentage
              $active={activeToken.isApproved && deposits}
              onClick={onDepositPercentageClick(1)}
            >
              100%
            </Percentage>
          </PercentageRow>
        </ContainerCol>
        <ContainerCol xs={6}>
          <Balance>
            {vaultFormatedBalance} {vaultSymbol}
          </Balance>
          <Row>
            <StyledInput
              value={wAmount}
              disabled={(withdraws && vaultBalance <= 0) || !withdraws}
              onChange={onWithdrawValueChange}
              onBlur={onWithdrawValueChange}
              type="number"
              required
              min={0}
              max={vaultBalance}
            />
          </Row>
          <PercentageRow around={'xs'}>
            <Percentage
              $active={withdraws && vaultBalance > 0}
              onClick={onWithdrawPercentageClick(0.25)}
            >
              25%
            </Percentage>
            <Percentage
              $active={withdraws && vaultBalance > 0}
              onClick={onWithdrawPercentageClick(0.5)}
            >
              50%
            </Percentage>
            <Percentage
              $active={withdraws && vaultBalance > 0}
              onClick={onWithdrawPercentageClick(0.75)}
            >
              75%
            </Percentage>
            <Percentage
              $active={withdraws && vaultBalance > 0}
              onClick={onWithdrawPercentageClick(1)}
            >
              100%
            </Percentage>
          </PercentageRow>
        </ContainerCol>
      </Row>
      <Row center={'xs'}>
        <Col xs={6}>
          <Row>
            {!activeToken.isApproved && (
              <ApprovalButton
                onClick={onApprove}
                text={t('action-unlock')}
                outline={false}
                disabled={!deposits}
                async={true}
              />
            )}
            {activeToken.isApproved && (
              <Button
                onClick={onDeposit}
                text={t('action-deposit')}
                disabled={!deposits}
                async={true}
              />
            )}
          </Row>
        </Col>
        <Col xs={6}>
          <Row center={'xs'}>
            <Col xs={6}>
              <Button
                onClick={onWithdraw}
                text={t('vault-withdraw')}
                disabled={(withdraws && vaultBalance <= 0) || !withdraws}
                async={true}
              />
            </Col>
            <Col xs={6}>
              <Button
                onClick={onWithdrawAll}
                text={t('vault-withdraw-all')}
                disabled={(withdraws && vaultBalance <= 0) || !withdraws}
                async={true}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row center={'xs'}>
        <Disclaimer xs={6}>
          {!deposits && 'Deposits are currently disabled for this vault.'}
        </Disclaimer>
        <Disclaimer xs={6}>
          {!withdraws && 'Withdraws are currently disabled for this vault.'}
          {withdraws && 'RLY is automatically claimed on withdraw.'}
        </Disclaimer>
      </Row>
    </AccordionContent>
  );
};

export const MemoizedVaultContent = React.memo(VaultContent);
